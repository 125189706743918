import React, { useState } from "react";

import { Link } from 'react-router-dom';
import { useNavigate, Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; 
import { login } from '../actions/auth'
import { checkBrandExist, getKitchenWithId } from '../actions/fifo'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import '../styles/footerHack.css'
import '../App.css'






toast.configure();
const Login =  ({ login, checkBrandExist, getKitchenWithId, isAuthenticated, user } ) => {
     const navigate = useNavigate();
    // const history = useHistory();

    let temp = false
    const [formData, setFormData] = useState({
                phoneNumber: '',
                password: ''
            }); 
        
            const { phoneNumber, password } = formData;
        
            const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

            const getKitchenWithIdCallback = e => {
                if(e.partnerWorkingInKitchen){
                    
                    navigate('/kitchen-details', {state:{kitchen:e.partnerWorkingInKitchen}})
                }
            }

            const loginCallback = e => {
                //debugger
                // console.log(e.user)

                if(e.user){
                    // console.log("userType: " + e.user.partnerType + " phone: " + e.user.phoneNumber +" userID: " + e.user.id)
                   
                    const brand = checkBrandExist()
                      brand.then(brand=> brand.data)
                      .then(brand => {
                          //debugger
                          if(brand.length === 0){
                                temp=true
                                if(e.user.partnerType === "Admin"){
                                    console.log("userType: " + e.user.partnerType + " phone: " + e.user.phoneNumber +" userID: " + e.user.id)
                                    // history.push('/brand-registration')  
                                    //return <Navigate key={temp} to='/brand-registration' replace /> 
                                    toast('Login Successfull')
                                    navigate('/brand-registration')
                                    

                                } else if(e.user.partnerType === "Manager"){
                                    toast('Login Successfull')
                                    if(e.user.partnerWorkingForKitchen){
                                        console.log("working: ", e.user.partnerWorkingForKitchen)
                                        getKitchenWithId(e.user.partnerWorkingForKitchen.id, getKitchenWithIdCallback)
                                    }
                                    // navigate('/dashboard')
                                    // return <Navigate key={temp} to='/dashboard' replace /> 
                                    // history.push('/dashboard')
                                }   
                          } else {

                            toast('Login Successfull')
                            
                            navigate('/dashboard')
                            // return <Navigate key={temp} to='/dashboard' replace /> 
                          }
                      })
              }               
            };

            const  myFunction = () => {
                var x = document.getElementById("myInput");
                if (x.type === "password") {
                  x.type = "text";
                } else {
                  x.type = "password";
                }
              }

              if(isAuthenticated){
                return <Navigate to="/dashboard" />
            }

            const onSubmit = e => {
                e.preventDefault();
                login(phoneNumber, password, loginCallback)
            }; 

            return (
              <div className="container mt-5 col-md-6 footer-hack ">
                <h1>Sign In</h1>
                <p>Sign into your account to get access</p>
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="form-group">
                    <label className="form-label mt-3">Phone Number: </label>
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Phone Number*"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label mt-3">Password: </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password*"
                      name="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                      minLength="8"
                      id="myInput"
                      required
                    />
                    <small><input type="checkbox" onClick={myFunction} style={{marginTop:"10px", marginRight:"10px"}} />Show Password</small>
                  </div>
                  <button className="btn  mt-3" type="submit">
                    Login
                  </button>
                </form>
                <p className="mt-3">
                  Don't have an Account? <br />{" "}
                  <Link to="/signup" className="text-decoration-none">
                    Sign Up
                  </Link>
                </p>
                <p className="mt-3">
                  Forgot your Password? <br />{" "}
                  <Link to="/reset-password" className="text-decoration-none">
                    Reset Password
                  </Link>
                </p>
              </div>
            );    


};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
})


export default connect(mapStateToProps, { login, checkBrandExist, getKitchenWithId }) (Login);