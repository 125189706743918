import React from 'react';
import { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUpdatedApp } from '../actions/fifo';
import '../App.css';

const Home = ({ isAuthenticated, getUpdatedApp }) => {
  const [updatedApp, setUpdatedApp] = useState([]);

  const getUpdatedAppCallback = (event) => {
    if (event.updatedApp) {
      console.log('Updated App: ', updatedApp);
      setUpdatedApp(event.updatedApp);
    }
  };

  useEffect(() => {
    getUpdatedApp(getUpdatedAppCallback);
  }, []);

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <div className='container theme-color  ' style={{ paddingBottom: '17%' }}>
      <a
        href='https://apkfab.com/scanx/io.ionic.starter/apk?h=011a708d21cf68b1e6f8c52f5e89eee48e72c47e95a0ed08da9e68f11c09df40'
        target='_blank'
        rel='noopener noreferrer'
        className='p-5 '
      >
        <button
          type='button'
          className='btn btn-success btn-md  btn-block text-center'
        >
          Download Scanx App
        </button>
      </a>
      <div className='mt-2 p-5 '>
        <h1 className='display-4'>Welcome to ScanX</h1>
        <p className='lead'>
          ScanX helps you trace your inventory through your supply chain using
          QR Labels.
        </p>
        <hr className='my-4' />
        {/* {
                 !isAuthenticated ? <div><p>Click the button below to log in.</p>
                 <Link className='btn    btn-lg' to='/login'>Login</Link></div> : ""
             } */}

        <p>Click the button below to log in.</p>
        <Link className='btn    btn-lg' to='/login'>
          Login
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getUpdatedApp })(Home);
