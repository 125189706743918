import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';
import '../styles/resetPasswordConfirm.css'
import '../styles/footerHack.css'

const ResetPasswordConfirm = ({ match, reset_password_confirm }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
                new_password: '',
                re_new_password:'',
            }); 
        
            const { new_password, re_new_password } = formData;
        
            const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
            const {uid, token} = useParams();
            
            const onSubmit = e => {
                e.preventDefault();
                //console.log(formData)
                reset_password_confirm(uid, token, new_password, re_new_password);
                setRequestSent(true);
            };

            const  myFunction = () => {
                var x = document.getElementsByClassName("myInput");
                if (x[0].type === "password" || x[1].type === "password") {
                  x[0].type = "text";
                  x[1].type = "text";
                } else {
                  x[0].type = "password";
                  x[1].type = "password";
                }
              }
            
            // Is the User Authenticated 
            if (requestSent)
                return <Navigate to='/' />;
    return (
      <div className="container mt-5 col-md-6 footer-hack">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label className="form-label mt-3">New Password: </label>
            <input
              className="form-control myInput"
              type="password"
              placeholder="New Password*"
              name="new_password"
              value={new_password}
              onChange={(e) => onChange(e)}
              minLength="8"
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-3">Re-New Password: </label>
            <input
              className="form-control myInput"
              type="password"
              placeholder="Confirm New Password*"
              name="re_new_password"
              value={re_new_password}
              onChange={(e) => onChange(e)}
              minLength="8"
              required
            />
            <small>
              <input
                type="checkbox"
                onClick={myFunction}
                style={{ marginTop: "10px", marginRight: "10px" }}
              />
              Show Password
            </small>
          </div>
          <div className="footer-hack">
            <button className="btn  mt-3" type="submit">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    );
};

export default connect(null, { reset_password_confirm }) (ResetPasswordConfirm);


