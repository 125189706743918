import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addStaffOrManager } from '../actions/auth';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/footerHack.css';

toast.configure();

const StaffRegistration = ({ addStaffOrManager }) => {
  const [partnerBrand, setPartnerBrand] = useState(null);
  const [partnerWorkingForKitchen, setPartnerWorkingForKitchen] =
    useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state.kitchen.brand.id);
  useEffect(() => {
    if (state.kitchen) {
      setPartnerBrand(state.kitchen.brand.id);
      setPartnerWorkingForKitchen(state.kitchen.id);
    }
  }, []);

  const [formData, setFormData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    phoneNumber: '',
    email: '',
    partnerType: 'Staff',
    password: '',
    re_password: '',
  });

  const [accountCreated, setAccountCreated] = useState(false);

  const {
    username,
    first_name,
    last_name,
    phoneNumber,
    email,
    partnerType,
    password,
    re_password,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  //what is this
  const myFunction = () => {
    let x = document.getElementsByClassName('myInput');
    if (x[0].type === 'password' || x[1].type === 'password') {
      x[0].type = 'text';
      x[1].type = 'text';
    } else {
      x[0].type = 'password';
      x[1].type = 'password';
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    console.log(partnerBrand, partnerWorkingForKitchen);
    if (password === re_password) {
      var createdStaff = addStaffOrManager(
        username,
        first_name,
        last_name,
        phoneNumber,
        email,
        partnerType,
        partnerBrand,
        partnerWorkingForKitchen,
        password,
        re_password
      );

      console.log(state);
      console.log(createdStaff);
      //⛳data from createdStaff
      createdStaff
        .then((data) => {
          if (data) {
            console.log('created Staff:', data);
            toast('Staff added Successfully!');
            toast('Please check your email and Activate your account!');
            return navigate('/kitchen-details', {
              state: { kitchen: state.kitchen },
            });
          } else {
            console.log(data);
            toast('Account creation failed!');
            toast(
              'Please check your user name, email or phone Number, one of these already exist!'
            );
          }
        })
        .catch((err) => console.log('err: ', err));

      // if (createdStaff) {
      //   toast('Staff Added Successfully');
      //   return navigate('/kitchen-details', {
      //     state: { kitchen: state.kitchen },
      //   });
      // }
    }
  };

  return (
    <div class="row mx-0 footer-hack">
      <div className="col-2 px-4 pt-5" style={{ marginLeft: '10px' }}>
        <h6 onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Go Back
        </h6>
      </div>

      <div className="container mt-5 col-md-6 ">
        <h1>Add Staff</h1>
        <p>Add Staff or manager for this kitchen</p>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label className="form-label" for="username">
              Username:{' '}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Username*"
              name="username"
              onChange={(e) => onChange(e)}
              value={username}
              required
            />

            <small style={{ color: 'wheat' }}>
              User-name and password must be different
            </small>
            <br></br>
            <small style={{ color: 'wheat' }}>
              User-name may contain alphanumeric, _ , @ , + , . and - characters
            </small>
          </div>

          <div className="form-group">
            <label className="form-label mt-3">First Name: </label>
            <input
              className="form-control"
              type="text"
              placeholder="First Name*"
              name="first_name"
              onChange={(e) => onChange(e)}
              value={first_name}
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-3">Last Name: </label>
            <input
              className="form-control"
              type="text"
              placeholder="Last Name*"
              name="last_name"
              onChange={(e) => onChange(e)}
              value={last_name}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label mt-3">Phone Number: </label>
            <input
              className="form-control"
              type="tel"
              placeholder="Phone Number*"
              name="phoneNumber"
              onChange={(e) => onChange(e)}
              value={phoneNumber}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label mt-3">Email Address: </label>
            <input
              className="form-control"
              type="email"
              placeholder="Email*"
              name="email"
              onChange={(e) => onChange(e)}
              value={email}
              required
            />
          </div>

          <label className="form-label mt-3">Partner Type: </label>
          <select
            className="form-select"
            aria-label="Default select example"
            value={partnerType}
            name="partnerType"
            onChange={(e) => onChange(e)}
          >
            {/* <option selected value="Staff">
                Select your user Type*
              </option> */}
            <option value="Manager">Manager</option>
            <option value="Staff" selected>
              Staff
            </option>
          </select>
          <small style={{ color: 'wheat' }}>
            If you don't select any, staff account will be created.
          </small>

          <div className="form-group">
            <label className="form-label mt-3">Password: </label>
            <input
              className="form-control myInput"
              type="password"
              placeholder="Password*"
              name="password"
              onChange={(e) => onChange(e)}
              value={password}
              minLength="8"
              required
            />
            <div className="validation-container">
              <ul className="validation-list">
                <li>Must be atleast 8 characters</li>
                <li>Must contain atleast 1 number</li>
                <li>Must contain atleast 1 special character</li>
              </ul>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label mt-3">Confirm Password: </label>
            <input
              className="form-control myInput"
              type="password"
              placeholder="Confirm Password*"
              name="re_password"
              onChange={(e) => onChange(e)}
              value={re_password}
              minLength="8"
              required
            />
            <small>
              <input
                type="checkbox"
                onClick={myFunction}
                style={{ marginTop: '10px', marginRight: '10px' }}
              />
              Show Password
            </small>
          </div>
          <button className="btn mt-3" type="submit">
            Register
          </button>
        </form>
        {/* <p className='mt-3'>
            Already have an Account? <Link to='/login'>Sign In</Link>
        </p> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { addStaffOrManager })(
  StaffRegistration
);
