import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getKitchenLiveInventory, deleteRecord } from '../actions/fifo';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../styles/kitchenLiveInventory.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const KitchenLiveInventory = ({
  getKitchenLiveInventory,
  deleteRecord,
  user,
}) => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const [kitchen, setKitchen] = useState(null);
  const [kitchenLiveInventoryData, setKitchenLiveInventoryData] = useState({});
  const [inventoryTriger, setInventoryTriger] = useState(0);
  // let inventoryTriger = 0;

  const navigate = useNavigate();

  // Function to group recipe or item data according to expiry date
  const expiryDateGroupHelper = (data) => {
    data = data.reduce((r, a) => {
      r[a.expDate] = r[a.expDate] || [];
      r[a.expDate].push(a);
      return r;
    }, Object.create(null));
    return data;
  };

  const getKitchenLiveInventoryCallback = (event) => {
    setIsLoading(true);

    if (event.kitchenLiveInventory[0]) {
      let kitchenLiveInventory = event.kitchenLiveInventory[0];

      // console.log("Result before grouping: ", kitchenLiveInventory)
      // Grouping data according to recipeName or itemName
      kitchenLiveInventory = kitchenLiveInventory.reduce((r, a) => {
        if (a.recipeID) {
          // console.log("in grouping recipeId: ", a.recipeID)
          r[a.recipeID.name] = r[a.recipeID.name] || [];
          r[a.recipeID.name].push(a);
        }
        if (a.itemID) {
          // console.log("in grouping itemId: ", a.itemID.name)
          r[a.itemID.name] = r[a.itemID.name] || [];
          r[a.itemID.name].push(a);
        }

        return r;
      }, Object.create(null));

      let kitchenLiveInventoryFormattedfinalData = {};
      // Grouping every recipe or item data according to expiry date
      for (let key in kitchenLiveInventory) {
        let lol = expiryDateGroupHelper(kitchenLiveInventory[key]);

        const sorted = Object.keys(lol)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = lol[key];

            return accumulator;
          }, {});

        // console.log("sorted:", lol)
        // lol.sort((a,b) => {
        //     return new Date(a) - new Date(b);
        // })
        // console.log("Vinniieeeee: ", sorted)
        kitchenLiveInventoryFormattedfinalData[key] = lol;
        setIsLoading(false);
      }
      console.log('result: ', kitchenLiveInventoryFormattedfinalData);
      setKitchenLiveInventoryData(kitchenLiveInventoryFormattedfinalData);
    }
  };

  const onDeleteInventoryCallback = (e) => {
    if (e) {
      console.log('asldbnkasnkjdbkja: ', e.deletedRecord);
      toast('Inventory Deleted Successfully');
      getKitchenLiveInventory(
        state.kitchen.id,
        getKitchenLiveInventoryCallback
      );
    }
  };

  const onDeleteInventory = (e, qrCode) => {
    e.preventDefault();
    if (qrCode) {
      if (user) {
        if (user.partnerType === 'Admin' || 'Manager') {
          alert(
            'This action cannot be undone. Do you want to delete this item? '
          );
          deleteRecord(qrCode, onDeleteInventoryCallback);
        } else {
          toast("You don't have right to delete");
        }
      }
    }
  };

  const reverseString = (str) => {
    return str.split('-').reverse().join('-');
  };

  // setInterval(() => {
  //   setInventoryTriger(inventoryTriger + 1);
  //   // inventoryTriger += 1
  //   // console.log("inventoryTriger: ", inventoryTriger);
  // }, 600000);

  useEffect(() => {
    if (state.kitchen) {
      // console.log("lol: ", state.kitchen.id)
      setKitchen(state.kitchen);
      getKitchenLiveInventory(
        state.kitchen.id,
        getKitchenLiveInventoryCallback
      );
    }
  }, []);

  const compareDates = (d1, d2) => {
    // console.log(d1.split('-'));
    let split = d1.split('-');
    let date1 = new Date(split[1] + '/' + split[2] + '/' + split[0]).getTime();
    // console.log(date1);

    // let date1 = new Date(d1.split('-').reverse().join('/')).getTime();
    let date2 = d2.getTime();
    // console.log(date2);
    // let date2 = new Date(d2.split('-').reverse().join('/')).getTime();

    if (date1 < date2) {
      // console.log(`${d1} is less than ${d2}`);
      return true;
    } else if (date1 > date2) {
      // console.log(`${d1} is greater than ${d2}`);
      return false;
    } else {
      console.log(`Both dates are equal`);
    }
  };

  // const [itemState, setItemState] = useState('notAboutToExpire');

  const shelfLifeForAboutToExpireStatus = (shelfLife, expDate, isFrozen) => {
    let aboutToExpireDays = Math.round((20 / 100) * shelfLife);
    let aboutToExpireValue;
    if (isFrozen === true) {
      aboutToExpireValue = 'fresh';
      return <span className='fresh'>Fresh (F)</span>;
    }

    let expiryDate = new Date(expDate);
    expiryDate.setDate(expiryDate.getDate() - aboutToExpireDays);

    let aboutToExpireDate = expiryDate; // Keep this as a Date object
    let currentDate = new Date(); // This is also a Date object

    let itemExpiryDate = new Date(expDate); // This is a Date object

    if (currentDate >= aboutToExpireDate && currentDate < itemExpiryDate) {
      aboutToExpireValue = 'aboutToExpire';
      return <span className='expiringSoon'>Expiring soon </span>;
    } else if (currentDate >= itemExpiryDate) {
      aboutToExpireValue = 'Expired';
      return <span className='expired'>Expired</span>;
    } else if (currentDate < aboutToExpireDate) {
      aboutToExpireValue = 'fresh';
      return <span className='fresh'>Fresh</span>;
    }
  };
  const shelfLifeForAboutToExpire = (shelfLife, expDate, isFrozen) => {
    if (isFrozen) {
      return 'Fresh f';
    } else {
      let aboutToExpireDays = Math.round((20 / 100) * shelfLife);
      let expiryDate = new Date(expDate);
      expiryDate.setDate(expiryDate.getDate() - aboutToExpireDays);

      let aboutToExpireDate = expiryDate; // Keep this as a Date object
      let currentDate = new Date(); // This is also a Date object

      let itemExpiryDate = new Date(expDate); // This is a Date object

      console.log('itemExpiryDate', itemExpiryDate);
      console.log('currentDate', currentDate);

      if (currentDate >= aboutToExpireDate && currentDate < itemExpiryDate) {
        return 'Expiring soon';
      } else if (currentDate >= itemExpiryDate) {
        return 'Expired';
      } else if (currentDate < aboutToExpireDate) {
        return 'Fresh';
      }
    }
  };
  const getCategoryStatus = (category) => {
    let status = 'Fresh'; // default status
    Object.keys(category).forEach((batchDate) => {
      const batch = category[batchDate];
      batch.forEach((item) => {
        const itemStatus = shelfLifeForAboutToExpire(
          item.recipeID
            ? item.recipeID.shelfLife
            : '' || item.itemID
            ? item.itemID.standardExpDays
            : '',
          item.expDate,
          item.recipeID
            ? item.recipeID.frozen
            : '' || item.itemID
            ? item.itemID.frozen
            : ''
        );
        if (itemStatus === 'Fresh f') {
          console.log('itemstatus', itemStatus);

          status = 'Fresh f';
        } else if (itemStatus === 'Expired') {
          status = 'Expired';
        } else if (itemStatus === 'Expiring soon' && status !== 'Expired') {
          status = 'Expiring soon';
        }
      });
    });
    if (status === 'Fresh f') {
      console.log('final status', status);
    }
    console.log(status);
    return status;
  };
  const mfgDate = (exp, shelfLife) => {
    if (shelfLife >= 0) {
      let date = new Date(exp);
      date.setDate(date.getDate() - shelfLife);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      let formatedDate = `${day}-${month}-${year}`;
      // console.log('newdate ' + formatedDate, 'oldDate' + exp);
      return formatedDate;
    } else {
      return '-';
    }
  };
  const getCurrentDate = () => {
    let newDate = new Date();
    // newDate = new Date(newDate).toLocaleDateString();
    // let finalDate = newDate.split('/').reverse().join('-');
    return newDate;
  };
  let todayDate = getCurrentDate();

  return (
    <div className='row mx-0 footer-hack'>
      <div className='col-2 px-4 pt-5' style={{ marginLeft: '10px' }}>
        <h6 onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Go Back
        </h6>
      </div>
      <div
        className=' col-12 px-0 kitchen-liveInventory-container  '
        style={{
          ...(Object.keys(kitchenLiveInventoryData).length > 3
            ? { paddingBottom: '10%' }
            : { paddingBottom: '40%' }),
        }}
      >
        <div
          className='row '
          style={{
            textAlign: 'center',
            borderBottom: '0px',
            color: '#CBA769',
            width: '99%',
          }}
        >
          <h2 className=' '>Live Inventory</h2>
        </div>
        {isLoading ? (
          <p>Loading may take around 2-3 mins ,please wait...</p>
        ) : (
          <div className='mt-5 mb-4 table-responsive col-12 border  '>
            <div
              className='accordion accordion-flush '
              id='accordionFlushExample'
            >
              {/* {console.log(kitchenLiveInventoryData)} */}
              {Object.keys(kitchenLiveInventoryData).map((e, i) => (
                <div className='accordion-item'>
                  <h2 className='accordion-header' id={'flush-headingOne' + i}>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={'#flush-collapseOne' + i}
                      aria-expanded='false'
                      aria-controls={'flush-collapseOne' + i}
                    >
                      {console.log(kitchenLiveInventoryData)}
                      <h5 className='accordionButton'>
                        <span className='accordionButtonSpan'>{`${e}`}</span>

                        <span className='accordionButtonSpan'>
                          {
                            {
                              'Fresh f': '🟢',
                              Fresh: '🟢',
                              'Expiring soon': '🟡',
                              Expired: '🔴',
                            }[getCategoryStatus(kitchenLiveInventoryData[e])]
                          }
                        </span>
                      </h5>
                    </button>
                  </h2>
                  <div
                    id={'flush-collapseOne' + i}
                    className='accordion-collapse collapse '
                    aria-labelledby={'flush-headingOne' + i}
                    data-bs-parent={'#accordionFlushExample' + i}
                  >
                    <div className='accordion-body'>
                      <div
                        className='accordion accordion-flush'
                        id='accordionFlushExample'
                      >
                        {Object.keys(kitchenLiveInventoryData[e]).map(
                          (x, j) => (
                            <div className='accordion-item'>
                              <h2
                                className='accordion-header'
                                id={'flush-headingOne' + j + i}
                              >
                                <button
                                  className='accordion-button collapsed'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={'#flush-collapseOne' + j + i}
                                  aria-expanded='false'
                                  aria-controls={'flush-collapseOne' + j + i}
                                >
                                  {/* {console.log(
                                    'this one' +
                                      // kitchenLiveInventoryData[e][x].map(
                                      //   (p, k) =>
                                      //     p.itemID
                                      //       ? p.itemID.standardExpDays
                                      //       : p.recipeID.shelfLife
                                      // )
                                      x +
                                      'today' +
                                      todayDate +
                                      'compared' +
                                      compareDates(x, todayDate)
                                  )} */}
                                  <h5 className='accordionButton'>
                                    <div className='batchGroup'>
                                      Batch {j + 1} (Exp: {reverseString(x)})
                                      (Total :{' '}
                                      <span className='totalItems'>
                                        {kitchenLiveInventoryData[e][x].length}
                                      </span>
                                      )
                                    </div>
                                    {/* {console.log(
                                      kitchenLiveInventoryData[e][x][0].recipeID
                                        ? kitchenLiveInventoryData[e][x][0]
                                            .recipeID.shelfLife
                                        : '' ||
                                          kitchenLiveInventoryData[e][x][0]
                                            .itemID
                                        ? kitchenLiveInventoryData[e][x][0]
                                            .itemID.standardExpDays
                                        : ''
                                    )} */}
                                    <span className=''>
                                      {shelfLifeForAboutToExpireStatus(
                                        kitchenLiveInventoryData[e][x][0]
                                          .recipeID
                                          ? kitchenLiveInventoryData[e][x][0]
                                              .recipeID.shelfLife
                                          : '' ||
                                            kitchenLiveInventoryData[e][x][0]
                                              .itemID
                                          ? kitchenLiveInventoryData[e][x][0]
                                              .itemID.standardExpDays
                                          : '',
                                        x,
                                        kitchenLiveInventoryData[e][x][0]
                                          .recipeID
                                          ? kitchenLiveInventoryData[e][x][0]
                                              .recipeID.frozen
                                          : '' ||
                                            kitchenLiveInventoryData[e][x][0]
                                              .itemID
                                          ? kitchenLiveInventoryData[e][x][0]
                                              .itemID.frozen
                                          : ''
                                      )}
                                    </span>
                                    {/* {shelfLifeForAboutToExpire(
                                      p.itemID
                                        ? p.itemID.standardExpDays
                                        : '' || p.recipeID
                                        ? p.recipeID.shelfLife
                                        : '',
                                      x
                                    )} */}
                                  </h5>
                                </button>
                              </h2>
                              <div
                                id={'flush-collapseOne' + j + i}
                                className='accordion-collapse collapse '
                                aria-labelledby={'flush-headingOne' + j + i}
                                data-bs-parent={
                                  '#accordionFlushExample' + j + i
                                }
                              >
                                <div className='accordion-body'>
                                  <table className='table '>
                                    <thead className='thea-dark'>
                                      <tr>
                                        <th scope='col'>No.</th>
                                        <th scope='col'>Recipe</th>
                                        <th scope='col'>Item</th>
                                        <th scope='col'>Quantity</th>
                                        <th scope='col'>
                                          {'Scanned By ('}Staff Name)
                                        </th>
                                        <th scope='col'>Time</th>
                                        <th scope='col'>Date</th>
                                        <th scope='col'>Manufactured Date</th>
                                        <th scope='col'>Source Kitchen</th>
                                        <th scope='col'>Action</th>
                                      </tr>
                                    </thead>
                                    {kitchenLiveInventoryData[e][x].map(
                                      (p, k) => (
                                        <tbody>
                                          <tr key={k}>
                                            <th scope='row'>{k + 1}</th>
                                            <td>
                                              {p.recipeID
                                                ? p.recipeID.name
                                                : '-'}
                                            </td>
                                            <td>
                                              {p.itemID ? p.itemID.name : '-'}{' '}
                                            </td>
                                            <td>
                                              {p.curr_unit + ' '}/
                                              {' ' + p.unitChange}{' '}
                                              {p.recipeID
                                                ? p.recipeID.unitForMeasure
                                                : p.itemID
                                                ? p.itemID.unitForMeasure
                                                : ''}
                                            </td>
                                            <td>
                                              {p.partnerID.first_name}{' '}
                                              {p.partnerID.last_name}
                                            </td>
                                            <td>
                                              {' '}
                                              {p.timestamp.substring(11, 19)}
                                            </td>
                                            <td>
                                              {reverseString(
                                                p.timestamp.substring(0, 10)
                                              )}{' '}
                                            </td>
                                            <td>
                                              {mfgDate(
                                                x,
                                                p.itemID
                                                  ? p.itemID.standardExpDays
                                                  : p.recipeID.shelfLife
                                              )}
                                            </td>
                                            <td>
                                              {p.sourceKitchen
                                                ? p.sourceKitchen.name
                                                : p.destKitchen.name}
                                            </td>
                                            <td>
                                              <button
                                                className='btn '
                                                onClick={(e) =>
                                                  onDeleteInventory(e, p.qrcode)
                                                }
                                              >
                                                Delete
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      )
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getKitchenLiveInventory,
  deleteRecord,
})(KitchenLiveInventory);
