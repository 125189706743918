import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from './hocs/Layout';
import Home from './containers/Home';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Activate from './containers/Activate';
import ResetPassword from './containers/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
import Dashboard from './containers/Dashboard';
import BrandRegistration from './containers/BrandRegistration';
import KitchenRegistration from './containers/KitchenRegistration';
import KitchenDetails from './containers/KitchenDetails';
import StaffRegistration from './containers/StaffRegistration';
import AddItem from './containers/AddItem';
import AddRecipe from './containers/AddRecipe';
import QrAndLabelMaker from './containers/QrAndLabelMaker';
import KitchenLiveInventory from './containers/KitchenLiveInventory';
import TransitInventory from './containers/TransitInventory';
import WasteInventory from './containers/WasteInventory';
import UpdateRecipe from './containers/UpdateRecipe';
import UpdateItem from './containers/UpdateItem';
import UsageInventory from './containers/UsageInventory';

import ProtectedRoute from './ProtectedRoute';

import { Provider } from 'react-redux';
import store from './store';

import './App.css';

const App = () => (
  <Provider store={store}>
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route
            path='/password/reset/confirm/:uid/:token'
            element={<ResetPasswordConfirm />}
          />
          <Route path='/activate/:uid/:token' element={<Activate />} />
          <Route element={<ProtectedRoute />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/brand-registration' element={<BrandRegistration />} />
            <Route
              path='/kitchen-registration'
              element={<KitchenRegistration />}
            />
            <Route path='/kitchen-details' element={<KitchenDetails />} />
            <Route path='/staff-registration' element={<StaffRegistration />} />
            <Route path='/add-item' element={<AddItem />} />
            <Route path='/add-recipe' element={<AddRecipe />} />
            <Route path='/qr-label-maker' element={<QrAndLabelMaker />} />
            <Route
              path='/kitchen-live-inventory'
              element={<KitchenLiveInventory />}
            />
            <Route path='/transit-inventory' element={<TransitInventory />} />
            <Route path='/waste-inventory' element={<WasteInventory />} />
            <Route path='/update-item/:id' element={<UpdateItem />} />
            <Route path='/usage-inventory' element={<UsageInventory />} />

            <Route path='/update-recipe/:id' element={<UpdateRecipe />} />
          </Route>
          {/* <Route  path='/brand-registration' element={<BrandRegistration/>} />
                    <Route  path='/kitchen-registration' element={<KitchenRegistration/>} />
                    <Route  path='/kitchen-details' element={<KitchenDetails/>} />
                    <Route  path='/staff-registration' element={<StaffRegistration/>} />
                    <Route  path='/add-item' element={<AddItem/>} />
                    <Route  path='/add-recipe' element={<AddRecipe/>} /> */}
        </Routes>
      </Layout>
    </Router>
  </Provider>
);

export default App;
