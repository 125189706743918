import React, {useEffect, useState }from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { addRecipe, addItemOrRecipeToKitchenForUnEnableRackSystem, getKitchenData, addRecipesWithExcel } from '../actions/fifo';
import { useNavigate } from 'react-router-dom';
import '../styles/footerHack.css'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'



const AddRecipe = ({addRecipe, addItemOrRecipeToKitchenForUnEnableRackSystem, getKitchenData, addRecipesWithExcel}) => {
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [brand, setBrand] =  useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [kitchen, setKitchen] = useState(null);
  
 
  const [formData, setFormData] = useState({
    recipeName: '',
    unitForMeasure: '',
    shelfLife:0,
    stdSize:0
  });

  const [frozen, setFrozen] = useState("False")
  const [document, setDocument] = useState(null)

  const {state} = useLocation()
  const navigate = useNavigate()

  const { recipeName, unitForMeasure, shelfLife, stdSize} = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  
    
  const getKitchenDataCallback = (event) => {
      if(event.kitchenData){
          const kitchenData = event.kitchenData;
          console.log(kitchenData)
          kitchenData.map((data, index)=>{
              if(index === 2){
                  console.log(data);
                  setItems(data)
              }
          })
      }
  }  

  const handleSelect  = (selectedItems ) => {
    
    const userSelected = [];
        for (let i=0; i<selectedItems.length; i++) {
          userSelected.push(parseInt(selectedItems[i].value));
        }
        setIngredients(userSelected);
        console.log("items: ", userSelected);
  }

  const addItemOrRecipeToKitchenForUnEnableRackSystemForExcelCallback = e =>{
    if(e.addedItemOrRecipeToKitchenForUnEnableRackSystem){
      console.log(e.addedItemOrRecipeToKitchenForUnEnableRackSystem)
      
  }
}

  const addRecipesWithExcelCallback = event =>{
    if(event.addedRecipes){
      console.log("lol addedRecipes: ", event.addedRecipes)
      console.log("Len:",event.addedRecipes.length)
      let count = 0
      for(let i=0; i<event.addedRecipes.length; i++){
        addItemOrRecipeToKitchenForUnEnableRackSystem(state.kitchen.id, item, event.addedRecipes[i].id, addItemOrRecipeToKitchenForUnEnableRackSystemForExcelCallback)
        count++
      }
      console.log("count: ", count)
      if(count === event.addedRecipes.length)
      {
        toast("Recipes Added Successfully")
        return navigate('/kitchen-details', {state:{kitchen:state.kitchen}})
      }
    }
  }

  const handleSubmitExcel = e => {
    e.preventDefault();
    
    addRecipesWithExcel(document, brand, state.kitchen.id,  addRecipesWithExcelCallback)
    
  }

    useEffect(() => {
      if(state){
        setBrand(state.kitchen.brand.id)
        setKitchen(state.kitchen.id)
      }
      console.log(state.kitchen.brand.id)
      getKitchenData(state.kitchen.id, getKitchenDataCallback);

    }, [])

    const addItemOrRecipeToKitchenForUnEnableRackSystemCallback = (e) =>{
        if(e.addedItemOrRecipeToKitchenForUnEnableRackSystem){
          console.log(e.addedItemOrRecipeToKitchenForUnEnableRackSystem)
          toast("Recipe Added Successfully")
          return navigate('/kitchen-details', {state:{kitchen:state.kitchen}})
        }
    }

    const addRecipeCallback = (e) =>{
      if(e.addedRecipe){
        addItemOrRecipeToKitchenForUnEnableRackSystem(state.kitchen.id, item, e.addedRecipe.id, addItemOrRecipeToKitchenForUnEnableRackSystemCallback)
      }

    }

    const onChangeFrozen = e =>{
      if(frozen === "False"){
        setFrozen("True")
      }
      if(frozen === "True"){
        setFrozen("False")
      }
    }

    const onSubmit = e => {
      e.preventDefault();
      console.log(formData, brand, ingredients)
      addRecipe(recipeName, unitForMeasure, shelfLife, brand, stdSize, ingredients, frozen, addRecipeCallback)
      
  }; 

  return (

    <div class="row mx-0 footer-hack">
        <div className="col-2 px-4 pt-5" style={{ marginLeft: "10px" }}>
          <h6 onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            Go Back
          </h6>
        </div>
    <div className="col-12">
      <div className="container mt-5 col-md-6 ">
        <h1>Add Recipes Using Excel Sheet</h1>
        <p> Upload a excel sheet with Recipe data to add your Recipes.</p>
        <a href={process.env.PUBLIC_URL + 'sample excel sheets/recipeDataFormat.xlsx'} download="recipeDataFormat.xlsx" style={{color:"#CBA769", fontWeight:"bold", textDecoration:"none"}}>Download Sample Excel Sheet</a>
        <form onSubmit={(e) => handleSubmitExcel(e)}>
          <div className="form-group">
            <label className="form-label mt-3">Upload File: </label>
            <input
              className="form-control"
              type="file"
              placeholder="Upload a excel sheet with item data*"
              accept=".xlsx"
              name="document"
              onChange={(e) => setDocument(e.target.files[0])}
              required
            />
          </div>
          <button className="btn  mt-3">Upload</button>
        </form>
      </div>

      <div className="container mt-5 col-md-6 ">
        <h1>Add Recipe</h1>
        <p> Fill the form to Add your Recipe.</p>

        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label className="form-label mt-3">Recipe Name: </label>
            <input
              className="form-control"
              type="text"
              placeholder="Recipe Name*"
              name="recipeName"
              value={recipeName}
              onChange={(e) => {e.target.value = e.target.value.toLocaleLowerCase(); onChange(e)}}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label mt-3">Unit Of Measure : </label>
            <input
              className="form-control"
              type="text"
              placeholder="Unit Of Measure*"
              name="unitForMeasure"
              value={unitForMeasure}
              onChange={(e) => {e.target.value = e.target.value.toLocaleLowerCase(); onChange(e)}}
              required
            />
          </div>

          <div className="form-group ">
            <label className="form-label mt-3"> Shelf Life: </label>
            <input
              className="form-control"
              type="number"
              placeholder="Shelf Life in Days*"
              name="shelfLife"
              value={shelfLife}
              min={0}
              onChange={(e) => onChange(e)}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label mt-3"> Standard Packet Size: </label>
            <input
              className="form-control"
              type="number"
              placeholder="Standard Packet Size kilogram and liter*"
              name="stdSize"
              value={stdSize}
              min={0}
              step='any'
              onChange={(e) => onChange(e)}
              required
            />
          </div>

          <div className="form-group ">
            <label className="form-label mt-3"> Select ingredients: </label>
            <select
              className="form-select mt-0.5"
              multiple={true}
              aria-label="multiple select ingredients"
              value={ingredients}
              onChange={(e) => {
                handleSelect(e.target.selectedOptions);
              }}
              name="ingredients"
            >
              {items.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className="form-check form-switch mt-3">
            <label
              className="form-check-label"
              htmlFor="frozen"
              style={{ color: "white" }}
            >
              Frozen
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="frozen"
              name="frozen"
              value="yes"
              onChange={(e) => onChangeFrozen(e)}
            />
          </div>
          <button className="btn  mt-3" type="submit">
            Add Recipe
          </button>
        </form>
      </div>
    </div>
    </div>
  );
}

export default connect(null, {addRecipe, addItemOrRecipeToKitchenForUnEnableRackSystem, getKitchenData, addRecipesWithExcel}) (AddRecipe);