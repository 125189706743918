export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAIL = 'ADD_STAFF_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const LOGOUT = 'LOGOUT';

export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';

export const CHECK_BRAND_EXIST_SUCCESS = 'CHECK_BRAND_EXIST_SUCCESS';
export const CHECK_BRAND_EXIST_FAIL = 'CHECK_BRAND_EXIST_FAIL';

export const BRAND_REGISTRATION_SUCCESS = 'BRAND_REGISTRATION_SUCCESS';
export const BRAND_REGISTRATION_FAIL = 'BRAND_REGISTRATION_FAIL';

export const GET_ALL_KITCHENS_SUCCESS = 'GET_ALL_KITCHENS_SUCCESS';
export const GET_ALL_KITCHENS_FAIL = 'GET_ALL_KITCHENS_FAIL';

export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAIL = 'ADD_LOCATION_FAIL';

export const KITCHEN_REGISTRATION_SUCCESS = 'KITCHEN_REGISTRATION_SUCCESS';
export const KITCHEN_REGISTRATION_FAIL = 'KITCHEN_REGISTRATION_FAIL';

export const ITEM_ADDED_SUCCESS = 'ITEM_ADDED_SUCCESS';
export const ITEM_ADDED_FAIL = 'ITEM_ADDED_FAIL';

export const RECIPE_ADDED_SUCCESS = 'RECIPE_ADDED_SUCCESS';
export const RECIPE_ADDED_FAIL = 'RECIPE_ADDED_FAIL';
export const RECIPE_UPDATE_SUCCESS = ' RECIPE_UPDATE_SUCCESS';
export const RECIPE_UPDATE_FAIL = 'RECIPE_UPDATE_FAIL';
export const GET_RECIPE_SUCCESS = 'GET_RECIPE_SUCCESS';
export const GET_RECIPE_FAIL = 'GET_RECIPE_FAIL';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAIL = 'GET_ITEM_FAIL';
export const UPDATE_STATE = 'UPDATE_STATE';
export const ITEM_UPDATE_SUCCESS = 'ITEM_UPDATE_SUCCESS';
export const ITEM_UPDATE_FAIL = 'ITEM_UPDATE_FAIL';

export const ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__SUCCESS =
  'ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__SUCCESS';
export const ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__FAIL =
  'ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__FAIL';

export const KITCHEN_DATA_LOADED_SUCCESS = 'KITCHEN_DATA_LOADED_SUCCESS';
export const KITCHEN_DATA_LOADED_FAIL = 'KITCHEN_DATA_LOADED_FAIL';
export const KITCHEN_USAGE_INVENTORY_LOADED_SUCCESS =
  'KITCHEN_USAGE_INVENTORY_LOADED_SUCCESS';
export const KITCHEN_USAGE_INVENTORY_LOADED_FAIL =
  'KITCHEN_USAGE_INVENTORY_LOADED_FAIL';

export const KITCHEN_LIVE_INVENTORY_LOADED_SUCCESS =
  'KITCHEN_LIVE_INVENTORY_LOADED_SUCCESS';
export const KITCHEN_LIVE_INVENTORY_LOADED_FAIL =
  'KITCHEN_LIVE_INVENTORY_LOADED_FAIL';

export const KITCHEN_WASTE_INVENTORY_LOADED_SUCCESS =
  'KITCHEN_WASTE_INVENTORY_LOADED_SUCCESS';
export const KITCHEN_WASTE_INVENTORY_LOADED_FAIL =
  'KITCHEN_WASTE_INVENTORY_LOADED_FAIL';

export const KITCHEN_TRANSIT_INVENTORY_LOADED_SUCCESS =
  'KITCHEN_TRANSIT_INVENTORY_LOADED_SUCCESS';
export const KITCHEN_TRANSIT_INVENTORY_LOADED_FAIL =
  'KITCHEN_TRANSIT_INVENTORY_LOADED_FAIL';

export const GET_KITCHEN_WITH_ID_SUCCESS = 'GET_KITCHEN_WITH_ID_SUCCESS';
export const GET_KITCHEN_WITH_ID_FAIL = 'GET_KITCHEN_WITH_ID_FAIL';

export const DELETE_ITEM_SUCCESSFUL = 'DELETE_ITEM_SUCCESSFUL';
export const DELETE_ITEM_FAIL = 'DELETE_ITEM_SUCCESSFUL';

export const DELETE_RECIPE_SUCCESSFUL = 'DELETE_RECIPE_SUCCESSFUL';
export const DELETE_RECIPE_FAIL = 'DELETE_RECIPE_FAIL';

export const ITEMS_ADDED_WITH_EXCEL_SUCCESS = 'ITEMS_ADDED_WITH_EXCEL_SUCCESS';
export const ITEMS_ADDED_WITH_EXCEL_FAIL = 'ITEMS_ADDED_WITH_EXCEL_FAIL';

export const RECIPES_ADDED_WITH_EXCEL_SUCCESS =
  'RECIPES_ADDED_WITH_EXCEL_SUCCESS';
export const RECIPES_ADDED_WITH_EXCEL_FAIL = 'RECIPES_ADDED_WITH_EXCEL_FAIL';

export const GET_UPDATED_APP_SUCCESS = 'GET_UPDATED_APP_SUCCESS';
export const GET_UPDATED_APP_FAIL = 'GET_UPDATED_APP_FAIL';

export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_FAIL = 'DELETE_RECORD_FAIL';

export const STAFF_DELETION_SUCCESS = 'STAFF_DELETION_SUCCESS';
export const STAFF_DELETION_FAIL = 'STAFF_DELETION_FAIL';
