import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from 'qrcode';
import Select from 'react-select';
import makeAnimated from 'react-select';
import '../styles/qrAndLabelMaker.css';
import '../styles/footerHack.css';

toast.configure();
const QrAndLabelMaker = () => {
  let defaultDate = new Date();
  let minDate = new Date();
  minDate.setDate(defaultDate.getDate() - 30);
  defaultDate.setDate(defaultDate.getDate());
  minDate = minDate.toISOString().substr(0, 10);
  defaultDate = defaultDate.toISOString().substr(0, 10);

  const dateStringData = {
    '01': 'JAN',
    '02': 'FEB',
    '03': 'MAR',
    '04': 'APR',
    '05': 'MAY',
    '06': 'JUN',
    '07': 'JUL',
    '08': 'AUG',
    '09': 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
  };

  const [kitchen, setKitchen] = useState(null);
  const [items, setItems] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [itemss, setItemss] = useState([]);

  const [name, setName] = useState('');
  const [qty, setQty] = useState(0);
  const [mfgDate, setMfgDate] = useState(defaultDate);
  const [shelfLife, setShelfLife] = useState('');
  const [expDate, setExpDate] = useState('');
  const [standerdPacketSize, setStanderdPacketSize] = useState(0);
  const [labelCount, setLabelCount] = useState(1);
  const [qrCodeArray, setQrCodeArray] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState('');
  const [batchNumber, setBatchNumber] = useState(0);
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.items) {
      setItems(state.items);
      setRecipes(state.recipes);
      setKitchen(state.kitchen);
      setItemss(state.items);
      let allItems = state.items;
      let allRecipes = state.recipes;
      let arr = [...allItems, ...allRecipes];

      let tempArray = [];
      arr.map((item, index) => {
        let tempObj = {
          value: '',
          label: '',
          itemx: {},
        };
        tempObj.value = item.id + '#' + index + '#' + item.name;
        if (item.frozen) {
          tempObj.label = item.name + ' (F)';
        } else {
          tempObj.label = item.name;
        }
        tempObj.itemx = item;
        tempArray[index] = tempObj;
      });

      console.log('Temp Array: ', tempArray);
      setItems(tempArray);
      //console.Console.log("arr:", state.items)
      console.log('on Qr page: ', state.kitchen, state.items, state.recipes);
    }

    return () => {
      setItems([]);
      setRecipes([]);
      setKitchen(null);
    };
  }, []);

  const getCurrentDate = () => {
    let separator = '/';
    let newDate = new Date();
    newDate = new Date(newDate);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return newDate.toLocaleDateString();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${
      date < 10 ? `0${date}` : `${date}`
    }${separator}${year}`;
  };

  const addDays = (curruntDate, days) => {
    console.log('rec this date: ', curruntDate);
    let separator = '-';
    var result = new Date(curruntDate);
    console.log('resultDate ', result);
    result.setDate(result.getDate() + days);
    console.log('res:', result.toLocaleDateString('en-GB'));
    let date = result.getDate();
    let month = result.getMonth() + 1;
    let year = result.getFullYear();
    return `${date < 10 ? `0${date}` : `${date}`}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year}`;
    // return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
    // return `${month<10?`${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}${separator}${year}`;
  };

  const handleSelect = (SelectedItem) => {
    console.log('new ', SelectedItem);
    if (SelectedItem) {
      let splitedData = SelectedItem.split('#');
      let itemId = splitedData[0];
      let index = splitedData[1];
      let name = splitedData[2];
      let itemType = '';

      console.log('itemId:', itemId);

      let foundItem = recipes.find(
        (item, index) => item.id == itemId && item.name == name
      );
      console.log('fo:', foundItem);
      if (!foundItem) {
        itemType = 'i';
        setSelectedItemType('i');
      } else {
        itemType = 'r';
        setSelectedItemType('r');
      }
      console.log('selectedItemType ', itemType);

      if (foundItem) {
        console.log('selectedItem lol ', foundItem);
      } else {
        foundItem = itemss.find(
          (item, index) => item.id == itemId && item.name == name
        );
        console.log('found: ', foundItem);
      }

      setSelectedItem(foundItem);

      setName(foundItem.name);
      setQty(foundItem.stdSize);
      setStanderdPacketSize(foundItem.stdSize);
      let todayDate = getCurrentDate();
      let curruntDate = new Date();
      var dd = String(curruntDate.getDate()).padStart(2, '0');
      let mm = String(curruntDate.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = curruntDate.getFullYear();

      curruntDate = dd + '-' + mm + '-' + yyyy;
      console.log('curr: ', curruntDate);
      setMfgDate(curruntDate);
      if (foundItem.standardExpDays) {
        setShelfLife(foundItem.standardExpDays);
      }
      if (foundItem.shelfLife) {
        setShelfLife(foundItem.shelfLife);
      }

      let todayDate2 = yyyy + '-' + mm + '-' + dd;
      setExpDate(
        addDays(
          todayDate2,
          foundItem.shelfLife ? foundItem.shelfLife : foundItem.standardExpDays
        )
      );

      let separator = '#';
      // let qrString = `item=${selectedItem.id}${separator}brand=${kitchen.brand.id}${separator}kitchen=${kitchen.id}${separator}shelfLife=${shelfLife}${separator}size=${standerdPacketSize}${separator}`

      let qrString = `${itemType}=${foundItem.id}${separator}${
        kitchen.brand.id
      }${separator}${kitchen.id}${separator}${
        foundItem.shelfLife ? foundItem.shelfLife : foundItem.standardExpDays
      }${separator}${foundItem.stdSize} ${
        foundItem.unitForMeasure
      }${separator}`;

      let arr = [];
      for (let i = 0; i < labelCount; i++) {
        if (mfgDate) {
          console.log('mfg ', mfgDate);
        }
        let timeStamp = new Date();
        let timeInMillisec = timeStamp.getTime() + Math.random();
        let exactTime = timeStamp.toTimeString();
        exactTime = exactTime.slice(0, 8);
        timeStamp = timeStamp.toLocaleDateString('en-GB');
        timeStamp = timeStamp.replace('/', '-');
        timeStamp = timeStamp.replace('/', '-');
        timeStamp = reverseString(timeStamp);
        console.log('in select: ', timeStamp);
        console.log('exactTime in select: ', exactTime);
        timeStamp = `${timeStamp}${separator}${exactTime}${separator}${timeInMillisec}`;

        let finalQrString = `${qrString}${timeStamp}`;
        console.log(finalQrString);
        QRCode.toDataURL(finalQrString).then((data) => {
          setQrCodeArray((qrCodeArray) => [...qrCodeArray, data]);
        });
      }
    }
    setQrCodeArray([]);
  };

  const handleOnChange = (unitChnage) => {
    let mfg = reverseString(mfgDate);
    if (true) {
      let separator = '#';
      setExpDate(
        addDays(
          mfg,
          selectedItem.shelfLife
            ? selectedItem.shelfLife
            : selectedItem.standardExpDays
        )
      );
      let qrString = `${selectedItemType}=${selectedItem.id}${separator}${kitchen.brand.id}${separator}${kitchen.id}${separator}${shelfLife}${separator}${unitChnage} ${selectedItem.unitForMeasure}${separator}`;
      console.log(qrString);
      let arr = [];
      for (let i = 0; i < labelCount; i++) {
        let timeStamp = new Date();
        let timeInMillisec = timeStamp.getTime() + Math.random();
        let exactTime = timeStamp.toTimeString();
        exactTime = exactTime.slice(0, 8);
        console.log('exactTime in unitchange: ', exactTime);
        timeStamp = mfgDate;
        timeStamp = reverseString(timeStamp);
        console.log('in qty change: ', timeStamp);
        timeStamp = `${timeStamp}${separator}${exactTime}${separator}${timeInMillisec}`;

        let finalQrString = `${qrString}${timeStamp}`;
        console.log(finalQrString);
        QRCode.toDataURL(finalQrString).then((data) => {
          setQrCodeArray((qrCodeArray) => [...qrCodeArray, data]);
        });
      }
    }

    setQrCodeArray([]);
  };

  const reverseString = (str) => {
    return str.split('-').reverse().join('-');
  };

  const handleMfgOnChnage = (mfg) => {
    console.log(' before rev mfg lol', mfg);
    mfg = reverseString(mfg);
    console.log(' before rev mfg lol', mfg);
    setMfgDate(mfg);
    mfg = reverseString(mfg);
    console.log('mfg lol', mfg);
    if (mfg) {
      let separator = '#';
      setExpDate(
        addDays(
          mfg,
          selectedItem.shelfLife
            ? selectedItem.shelfLife
            : selectedItem.standardExpDays
        )
      );
      let qrString = `${selectedItemType}=${selectedItem.id}${separator}${
        kitchen.brand.id
      }${separator}${kitchen.id}${separator}${shelfLife}${separator}${
        standerdPacketSize ? `${qty}` : standerdPacketSize
      } ${selectedItem.unitForMeasure}${separator}`;
      console.log(qrString);
      let arr = [];
      for (let i = 0; i < labelCount; i++) {
        let timeStamp = new Date();
        let timeInMillisec = timeStamp.getTime() + Math.random();
        let exactTime = timeStamp.toTimeString();
        exactTime = exactTime.slice(0, 8);
        // console.log('exactTime in mfg change: ', exactTime);
        timeStamp = reverseString(mfg);
        timeStamp = reverseString(timeStamp);
        // console.log('in mfg change: ', timeStamp);
        timeStamp = `${timeStamp}${separator}${exactTime}${separator}${timeInMillisec}`;

        let finalQrString = `${qrString}${timeStamp}`;
        console.log(finalQrString);
        QRCode.toDataURL(finalQrString).then((data) => {
          setQrCodeArray((qrCodeArray) => [...qrCodeArray, data]);
        });
      }
    }
    setQrCodeArray([]);
  };

  const handleLabelCountChange = (count) => {
    if (count) {
      let separator = '#';
      let qrString = `${selectedItemType}=${selectedItem.id}${separator}${
        kitchen.brand.id
      }${separator}${kitchen.id}${separator}${shelfLife}${separator}${
        standerdPacketSize ? `${qty}` : standerdPacketSize
      } ${selectedItem.unitForMeasure}${separator}`;
      console.log(qrString);
      let arr = [];
      for (let i = 0; i < count; i++) {
        let timeStamp = new Date();
        let timeInMillisec = timeStamp.getTime() + Math.random();
        let exactTime = timeStamp.toTimeString();
        exactTime = exactTime.slice(0, 8);
        // console.log('exactTime in label count: ', exactTime);
        timeStamp = mfgDate;
        timeStamp = reverseString(timeStamp);
        // console.log('in label qty change: ', timeStamp);
        timeStamp = `${timeStamp}${separator}${exactTime}${separator}${timeInMillisec}`;

        let finalQrString = `${qrString}${timeStamp}`;
        console.log(finalQrString);
        QRCode.toDataURL(finalQrString).then((data) => {
          setQrCodeArray((qrCodeArray) => [...qrCodeArray, data]);
        });
      }
    }
    setQrCodeArray([]);
  };

  const onClickPrintHandler = (e) => {
    console.log('label count: ', labelCount);
    let labels = [];
    let documetContent = document.body.innerHTML;
    let documentTitle = document.title;
    let tickets = ``;
    for (let i = 0; i < labelCount; i++) {
      let element = document.getElementById(i);
      tickets =
        tickets + `<div class="label-container"> ${element.innerHTML}</div>`;
    }

    //   document.body.innerHTML = tickets
    //   need to add event listners that will replace document.body.innerHTML with documetContent
    window.onbeforeprint = (event) => {
      document.getElementById('printableContent').style.display = 'block';
      document.getElementById('wholeContent').style.display = 'none';
      document.getElementById('footer-hide').style.display = 'none';
      document.getElementById('navbar-hide').style.display = 'none';
      document.getElementById('goBackButon').style.display = 'none';

      console.log('Before print');
      document.getElementById('printableContent').innerHTML = tickets;
      document.title = `${selectedItem.name}  Exp: ${expDate}`;
    };

    window.onafterprint = (event) => {
      document.getElementById('printableContent').style.display = 'none';
      document.getElementById('wholeContent').style.display = 'block';
      document.getElementById('footer-hide').style.display = 'block';
      document.getElementById('navbar-hide').style.display = 'block';
      document.getElementById('goBackButon').style.display = 'block';

      console.log('After print');
      // document.body.innerHTML = documetContent
      // window.location.reload(true);
      // return navigate('/qr-label-maker', {state: {kitchen:kitchen, items: items, recipes: recipes}})
    };

    window.print();
    //   console.log("after print backup:", documetContent)
    //   document.body.innerHTML = documetContent
    //   window.location.reload(false);
    // return navigate('/qr-label-maker', {state: {kitchen:state.kitchen, items: items, recipes: recipes}})
  };

  return (
    <div className="row mx-0 footer-hack">
      <div
        className="col-2 px-4 pt-5"
        style={{ marginLeft: '10px' }}
        id="goBackButon"
      >
        <h6 onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Go Back
        </h6>
      </div>
      <div className="row mx-0" id="wholeContent">
        <div className="col-12 text-center px-0 coKit-fifo-title primary-color">
          <span className="recipe-menu-title " style={{ color: '#CBA769' }}>
            Label Maker
          </span>
        </div>

        <div className="col-12 px-0">
          <div className="row mx-0 justify-content-center labels-and-form-container">
            <div className="col-5 px-0 labels-container ">
              <div className="row mx-0 justify-content-center">
                {qrCodeArray.map(
                  (qr, index) =>
                    selectedItem && (
                      <div id="new-ticket" style={{ pageBreakAfter: 'always' }}>
                        <div
                          className="row mx-5 label-container"
                          key={index}
                          id={index}
                        >
                          {/* label info container */}
                          <div
                            className="col-6 px-0    label-info-container"
                            // style={{backgroundColor:"white", textAlign:"center", position: "relative"}}
                          >
                            <div className="odd-heading-container">
                              <div>
                                <div className="row justify-content-center odd-heading-background mx-0">
                                  {qty != standerdPacketSize && (
                                    <div className="col-8 px-0 odd-heading ">
                                      ODD SIZE
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div>
                                <div className="row">
                                  {/* <label className='col-3 color-black name-label' htmlFor="item-recipe-name ">Name:</label> */}
                                  <p className="col-11 color-black pr-0 pl-2 recipe-name">
                                    {' '}
                                    {name.toUpperCase()} {batchNumber}
                                    {selectedItem.frozen ? ' (F)' : ''}{' '}
                                  </p>
                                </div>
                                <div className="row ">
                                  <label
                                    htmlFor="item-recipe-qty"
                                    className="col-3 color-black qty-label"
                                  >
                                    QTY:
                                  </label>
                                  <p className="col-8 color-black qty">
                                    {' '}
                                    {qty}{' '}
                                    {selectedItem.unitForMeasure.toUpperCase()}
                                  </p>
                                </div>
                                <div className="row ">
                                  <label
                                    htmlFor="item-recipe-dom"
                                    className="col-3 color-black mfg-label"
                                  >
                                    MFG:
                                  </label>
                                  <p className="col-8 color-black mfg">
                                    {mfgDate.toString().substr(0, 2)}{' '}
                                    {
                                      dateStringData[
                                        mfgDate.toString().substr(3, 2)
                                      ]
                                    }
                                  </p>
                                </div>
                                {selectedItem.frozen ? (
                                  <div className="row ">
                                    <label
                                      htmlFor="item-recipe-dom"
                                      className="col-3 color-black exp-label"
                                    >
                                      ADF:
                                    </label>
                                    <p className="col-8 color-black exp">
                                      {selectedItem.standardExpDays ===
                                      undefined
                                        ? selectedItem.shelfLife
                                        : selectedItem.standardExpDays}{' '}
                                      DAYS
                                    </p>
                                  </div>
                                ) : (
                                  <div className="row ">
                                    <label
                                      htmlFor="item-recipe-dom"
                                      className="col-3 color-black exp-label"
                                    >
                                      EXP:
                                    </label>
                                    <p className="col-8 color-black exp">
                                      {expDate.toString().substr(0, 2)}{' '}
                                      {
                                        dateStringData[
                                          expDate.toString().substr(3, 2)
                                        ]
                                      }
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* QR container */}
                          <div className="col-6 px-0 qr-container">
                            <img className="qr-image" key={index} src={qr} />
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>

            <div className="col-6 px-0 pl-3 form-container">
              <div className="form-div">
                <form className="form-row">
                  <div className="row mt-1 my-3">
                    <Select
                      options={items}
                      // theme={customTheme}
                      className="mt-1 "
                      placeholder="Search/Select an item or recipe"
                      isSearchable
                      onChange={(e) => {
                        console.log('e in selcet:', e.value);
                        handleSelect(e.value);
                      }}
                    />

                    {/* <select
                      className="form-select mt-0.5"
                      aria-label="Default select example"
                      value={selectedItem}
                      onChange={(e) => handleSelect(e.target.value)}
                      name="selectedItem"
                    >
                      <option value="Select an Item">Select an Item</option>
                      {items.map((item, index) => (
                        <option
                          value={item.id + "#" + index + "#" + item.name}
                          key={item.id + "#" + index + "#" + item.name}
                        >
                          {" "}
                          {item.name} {item.frozen ? "(F)" : ""}{" "}
                        </option>
                      ))}
                    </select> */}
                  </div>
                  <div className="form-group   row mt-1">
                    <label htmlFor="name" className="color-white col-md-3">
                      Name:{' '}
                    </label>
                    <input
                      type="text"
                      disabled={true}
                      placeholder="name*"
                      name="name"
                      id="name"
                      className="col-md-6"
                      required={true}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="row mt-2">
                    <label
                      htmlFor="labelCount"
                      className="color-white col-md-3"
                    >
                      Batch:
                    </label>
                    <input
                      type="number"
                      min="1"
                      placeholder="Batch Number"
                      name="batchNumber"
                      id="batchNumber"
                      className="col-md-6"
                      required={true}
                      value={batchNumber}
                      onChange={(e) => {
                        setBatchNumber(e.target.value);
                        handleLabelCountChange(labelCount);
                      }}
                    />
                  </div>
                  <div className="row mt-2">
                    <label htmlFor="qty" className="color-white col-md-3">
                      Quantity:{' '}
                    </label>
                    <input
                      type="number"
                      min="0"
                      placeholder="Quantity*"
                      name="qty"
                      id="qty"
                      className="col-md-6"
                      required={true}
                      value={qty}
                      onChange={(e) => {
                        setQty(e.target.value);
                        handleOnChange(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row mt-2">
                    <label htmlFor="mfgDate" className="color-white col-md-3">
                      MFG Date:{' '}
                    </label>
                    <input
                      type="date"
                      placeholder="Manufacturing Date*"
                      name="mfgDate"
                      id="mfgDate"
                      className="col-md-6"
                      defaultValue={mfgDate}
                      min={minDate}
                      max={defaultDate}
                      onChange={(e) => {
                        // setMfgDate(e.target.value);
                        handleMfgOnChnage(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row mt-2">
                    <label
                      htmlFor="shelfLife"
                      className=" color-white col-md-3"
                    >
                      Shelf Life:{' '}
                    </label>
                    <input
                      type="number"
                      placeholder="Shelf Life*"
                      name="shelfLife"
                      id="shelfLife"
                      className="col-md-6"
                      required={true}
                      value={shelfLife}
                      disabled={true}
                      onChange={(e) => setShelfLife(e.target.value)}
                    />
                  </div>

                  <div className="row mt-2">
                    <label htmlFor="expDate" className="color-white col-md-3">
                      Exp Date:{' '}
                    </label>
                    <input
                      type="text"
                      placeholder="Expiry Date*"
                      name="expDate"
                      id="expDate"
                      className="col-md-6"
                      disabled={true}
                      value={expDate}
                      onChange={(e) => setExpDate(e.target.value)}
                    />
                  </div>

                  {/* <div className='row mt-2'>
                                        <label htmlFor="expDate" className='col-md-3'>Exp Date: </label>
                                        <input type="date" placeholder='Expiry Date*' name="expDate" id="expDate" className='col-md-6'   value={expDate}  onChange={e => setExpDate(e.target.value)} />
                                    </div> */}

                  <div className="row mt-2">
                    <label
                      htmlFor="labelCount"
                      className="color-white col-md-3"
                    >
                      Label Quantity:{' '}
                    </label>
                    <input
                      type="number"
                      min="1"
                      placeholder="Number Of Labels*"
                      name="labelCount"
                      id="labelCount"
                      className="col-md-6"
                      required={true}
                      value={labelCount}
                      onChange={(e) => {
                        setLabelCount(e.target.value);
                        handleLabelCountChange(e.target.value);
                      }}
                    />
                  </div>

                  {/* <button className='btn  print-button ' type='button' onClick={e => onClickPrintHandler(e)}> Print</button> */}
                </form>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <button
              className="btn  print-button "
              onClick={(e) => onClickPrintHandler(e)}
            >
              {' '}
              Print
            </button>
          </div>
        </div>
      </div>
      <div className=" row mx-0" id="printableContent"></div>
    </div>
  );
};

export default QrAndLabelMaker;
