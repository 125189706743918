import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { brandRegistration } from '../actions/fifo';
import '../styles/footerHack.css'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'





toast.configure();




const BrandRegistration = ({ brandRegistration, isAuthenticated, user, brandExist}) => {
    const navigate = useNavigate();
    // const history = useHistory();
    
    const [formData, setFormData] = useState({
                name: ''
            }); 
        
            const { name } = formData;
        
            const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

            const brandRegistrationCallback = e => {
                if(e.registeredBrand)
                {
                    toast("Brand is added successfully.")
                    navigate('/dashboard')
                }
            }

            const onSubmit = e => {
                e.preventDefault();
                brandRegistration(name, brandRegistrationCallback);
            };



    return ( 
        
        <div className='container mt-5 col-md-6 footer-hack' style={{paddingBottom:'20%'}}>
        <h1>Brand Registration</h1>
        <p>Enter your brand name for completing the Registration</p>
        <form onSubmit={e => onSubmit(e)}>
            
            <div className='form-group'>
                <label className='form-label mt-3'>Brand Name: </label>
                <input
                    className='form-control'
                    type='text'
                    placeholder='Brand Name*'
                    name='name'
                    value={name}
                    onChange={e => onChange(e)}
                    required
                />
            </div>
            
            <button className='btn  mt-3' type='submit'>Register</button>
        </form> 
    </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    brandExist: state.fifo.brandExist
})

export default connect(mapStateToProps, { brandRegistration })  (BrandRegistration);


