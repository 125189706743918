import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.css';
import '../App.css';

const Footer = () => {
  return (
    <div
      className="col-12 px-0  footer-container position-fixed bottom-0 theme-color "
      style={{ opacity: '1' }}
      id="footer-hide"
    >
      <div className="row mx-0">
        <div className="col-12 px-0  text-center ">
          {/* <Link
        className="btn btn-link btn-floating btn-lg text-dark m-1"
        exact to="/"
        role="button"
        data-mdb-ripple-color="dark"
        >Cokit</Link>

     
      <Link
        className="btn btn-link btn-floating btn-lg text-dark m-1"
        exact to="/"
        role="button"
        data-mdb-ripple-color="dark"
        >Cokit</Link>

      
      <Link
        className="btn btn-link btn-floating btn-lg text-dark m-1"
        exact to="/"
        role="button"
        data-mdb-ripple-color="dark"
        >Cokit</Link>

    
      <Link
        className="btn btn-link btn-floating btn-lg text-dark m-1"
        exact to="/"
        role="button"
        data-mdb-ripple-color="dark"
        >Cokit</Link>

     
      <Link
        className="btn btn-link btn-floating btn-lg text-dark m-1"
        exact to="/"
        role="button"
        data-mdb-ripple-color="dark"
        >Cokit</Link>
      
      <Link
        className="btn btn-link btn-floating btn-lg text-dark m-1"
        exact to="/"
        role="button"
        data-mdb-ripple-color="dark"
        >Cokit</Link> */}
        </div>

        <div className="col-12 px-0 text-center text-white p-3 ">
          © 2020 Copyright:
          <a
            className="text-white"
            exect
            // to="https://cokittech.s3.ap-south-1.amazonaws.com/index.html"
            href="https://www.eatx.ai"
            rel="noopener noreferrer"
            target="_blank"
          >
            {' '}
            EatX.ai
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
