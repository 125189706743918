import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import '../App.css';
import '../styles/navbar.css';

const Navbar = ({ logout, isAuthenticated }) => {
  const guestLinks = () => (
    <Fragment>
      <div className="row mx-0 mt-5 pt-5   ">
        <div className="col-4 px-0">
          <Link className="nav-link" to="/login">
            <h6>Login</h6>
          </Link>
        </div>
        <div className="col px-0">
          <Link className="nav-link" to="/signup">
            <h6>Sign Up</h6>
          </Link>
        </div>
      </div>
    </Fragment>
  );

  const authLinks = () => (
    <Fragment>
      <div className="row mx-0">
        <div className="col-5 pt-3 px-0">
          <Link className="nav-link" to="/dashboard">
            <h6>All Kitchens</h6>
          </Link>
        </div>
      </div>
    </Fragment>
  );

  const authLinks2 = () => (
    <Fragment>
      <div
        className="col-2 px-0 pt-1  "
        style={{ marginLeft: '370%', marginTop: '-17%' }}
      >
        <Link className="nav-link " to="#!" onClick={logout}>
          <h6>Logout</h6>
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div
      className="row mx-4  navbar navbar-fixed navbar-expand-lg "
      id="navbar-hide"
    >
      <div className="col-6 px-0">
        <div className="row mx-0">
          <div className="col-2 px-0 ">
            <Link
              className="nav-link navbar-brand"
              to={isAuthenticated ? '/dashboard' : '/'}
            >
              <img src="/images/logo.png" alt="" height="55px" width="45px" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="col-6 px-0 ">
            {isAuthenticated ? authLinks() : guestLinks()}
          </div>
        </div>

        {isAuthenticated && (
          <div className="col-6 px-0" style={{ textAlign: 'right' }}>
            {isAuthenticated ? authLinks2() : ''}
          </div>
        )}
      </div>
      {/* <div className="col-6 px-0" style={{'textAlign': 'right'}}>
                { isAuthenticated ? authLinks2() : ''}
            </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Navbar);
