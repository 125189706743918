import { combineReducers } from 'redux';
import auth from './auth';
import fifo from './fifo';
//import profile from './profile';

export default combineReducers({
    auth,
    fifo,
    //profile
});
