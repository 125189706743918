import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../actions/auth';
import '../styles/resetPassword.css'
import '../styles/footerHack.css'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
toast.configure();



const ResetPassword = ({ reset_password }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
                email: '',
                
            }); 
        
            const { email } = formData;
        
            const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
        
            const onSubmit = e => {
                e.preventDefault();
                //console.log(formData)
                reset_password(email);
                setRequestSent(true);
            };
            
            // Is the User Authenticated 
            if (requestSent){
                toast(" Please check your email for reset password link!")
                return <Navigate to='/' />;
            }
                
    return (
        <div className='container mt-5 col-md-6 footer-hack'>
        <h1>Request Password Reset:</h1>
        <p>Please enter your registerd email to get the reset password link</p>
        <form onSubmit={e => onSubmit(e)}>
            
            <div className=' form-group'>
                <label className='form-label mt-3'>Email: </label>
                <input
                    className='form-control'
                    type='email'
                    placeholder='Email*'
                    name='email'
                    value={email}
                    onChange={e => onChange(e)}
                    required
                />
            </div>
           
            <div className="footer-hack">
                <button className='btn   mt-3' type='submit'>Reset Password</button>
            </div>
        </form>
    
         
    </div>
    );
};

export default connect(null, { reset_password }) (ResetPassword);


