import React, { useEffect, useState } from 'react'
import Navbar  from '../components/Navbar';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../actions/auth';
import Footer from '../components/Footer'
import Breadcrumbs from '../components/Breadcrumbs';
import '../App.css'

const Layout = ({ checkAuthenticated, load_user,  children, isAuthenticated }) => {
    let loadedUser = null; 
    const loadUser = (e) => {
        if(e.user){
            // console.log("lol" + e.user.phoneNumber)
            loadedUser = e.user
        }
        

    }

        useEffect(() => {
        checkAuthenticated();
        load_user(loadUser);
    });
    console.log("childern", children)

    return (
        
                    <div className='theme-color'> 
                        <Navbar />
                        {/* {isAuthenticated  ?  <Breadcrumbs /> : ""} */}
                        
                        {children}
                        <Footer /> 
                     </div> 
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { checkAuthenticated, load_user}) (Layout);