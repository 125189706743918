import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_RECIPE_SUCCESS,
  GET_RECIPE_FAIL,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAIL,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_FAIL,
  RECIPE_UPDATE_SUCCESS,
  RECIPE_UPDATE_FAIL,
  UPDATE_STATE,
  CHECK_BRAND_EXIST_SUCCESS,
  CHECK_BRAND_EXIST_FAIL,
  BRAND_REGISTRATION_SUCCESS,
  BRAND_REGISTRATION_FAIL,
  GET_ALL_KITCHENS_SUCCESS,
  GET_ALL_KITCHENS_FAIL,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAIL,
  KITCHEN_REGISTRATION_SUCCESS,
  KITCHEN_REGISTRATION_FAIL,
  ITEM_ADDED_SUCCESS,
  ITEM_ADDED_FAIL,
  KITCHEN_DATA_LOADED_SUCCESS,
  KITCHEN_DATA_LOADED_FAIL,
  ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__SUCCESS,
  ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__FAIL,
  RECIPE_ADDED_SUCCESS,
  RECIPE_ADDED_FAIL,
  KITCHEN_LIVE_INVENTORY_LOADED_SUCCESS,
  KITCHEN_LIVE_INVENTORY_LOADED_FAIL,
  KITCHEN_TRANSIT_INVENTORY_LOADED_SUCCESS,
  KITCHEN_TRANSIT_INVENTORY_LOADED_FAIL,
  KITCHEN_WASTE_INVENTORY_LOADED_SUCCESS,
  KITCHEN_WASTE_INVENTORY_LOADED_FAIL,
  GET_KITCHEN_WITH_ID_SUCCESS,
  GET_KITCHEN_WITH_ID_FAIL,
  DELETE_ITEM_SUCCESSFUL,
  DELETE_ITEM_FAIL,
  DELETE_RECIPE_SUCCESSFUL,
  DELETE_RECIPE_FAIL,
  ITEMS_ADDED_WITH_EXCEL_SUCCESS,
  ITEMS_ADDED_WITH_EXCEL_FAIL,
  RECIPES_ADDED_WITH_EXCEL_SUCCESS,
  RECIPES_ADDED_WITH_EXCEL_FAIL,
  GET_UPDATED_APP_SUCCESS,
  GET_UPDATED_APP_FAIL,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_FAIL,
  STAFF_DELETION_SUCCESS,
  STAFF_DELETION_FAIL,
  KITCHEN_USAGE_INVENTORY_LOADED_SUCCESS,
  KITCHEN_USAGE_INVENTORY_LOADED_FAIL,
} from './types';

export const checkBrandExist = () => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fifo/getallAndAddBrand `,
        config
      );
      dispatch({
        type: CHECK_BRAND_EXIST_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CHECK_BRAND_EXIST_FAIL,
      });
    }
  } else {
    dispatch({
      type: CHECK_BRAND_EXIST_FAIL,
    });
  }
};

export const brandRegistration = (name, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    const body = JSON.stringify({ name });

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fifo/getallAndAddBrand `,
        body,
        config
      );
      console.log(response.data);
      dispatch({
        type: BRAND_REGISTRATION_SUCCESS,
        payload: response.data,
      });
      callback({ registeredBrand: response.data });
    } catch (error) {
      console.log(error);
      dispatch({
        type: BRAND_REGISTRATION_FAIL,
      });
    }
  } else {
    dispatch({
      type: BRAND_REGISTRATION_FAIL,
    });
  }
};

export const getAllKitchens = (callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fifo/getallAndAddKitchen `,
        config
      );
      dispatch({
        type: GET_ALL_KITCHENS_SUCCESS,
        payload: response.data,
      });
      callback({ allKitchensData: response.data });
    } catch (error) {
      dispatch({
        type: GET_ALL_KITCHENS_FAIL,
      });
    }
  } else {
    dispatch({
      type: GET_ALL_KITCHENS_FAIL,
    });
  }
};

export const addLocation =
  (locationLongitude, locationLatitude, name, fullAddress, callback) =>
  async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      const body = JSON.stringify({
        locationLongitude,
        locationLatitude,
        name,
        fullAddress,
      });

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fifo/getallAndAddLocation `,
          body,
          config
        );
        console.log(response.data);
        dispatch({
          type: ADD_LOCATION_SUCCESS,
          payload: response.data,
        });
        callback({ addedLocation: response.data });
      } catch (error) {
        console.log(error);
        dispatch({
          type: ADD_LOCATION_FAIL,
        });
      }
    } else {
      dispatch({
        type: ADD_LOCATION_FAIL,
      });
    }
  };

export const kitchenRegistration =
  (brand, location, name, enableRackSystem, enableBoxSystem, type, callback) =>
  async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      const body = JSON.stringify({
        brand,
        location,
        name,
        enableRackSystem,
        enableBoxSystem,
        type,
      });

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fifo/getallAndAddKitchen `,
          body,
          config
        );
        console.log(response.data);
        dispatch({
          type: KITCHEN_REGISTRATION_SUCCESS,
          payload: response.data,
        });
        callback({ registeredKitchen: response.data });
      } catch (error) {
        console.log(error);
        dispatch({
          type: KITCHEN_REGISTRATION_FAIL,
        });
      }
    } else {
      dispatch({
        type: KITCHEN_REGISTRATION_FAIL,
      });
    }
  };

export const addItem =
  (
    name,
    unitForMeasure,
    description,
    itemType,
    standardExpDays,
    stdSize,
    brand,
    frozen,
    callback
  ) =>
  async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      const body = JSON.stringify({
        name,
        unitForMeasure,
        description,
        itemType,
        standardExpDays,
        stdSize,
        brand,
        frozen,
      });
      console.log('Body:', body);

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fifo/getallAndAddItem`,
          body,
          config
        );
        console.log(response.data);
        dispatch({
          type: ITEM_ADDED_SUCCESS,
          payload: response.data,
        });
        callback({ addedItem: response.data });
      } catch (error) {
        console.log(error);
        dispatch({
          type: ITEM_ADDED_FAIL,
        });
      }
    } else {
      dispatch({
        type: ITEM_ADDED_FAIL,
      });
    }
  };
export const updateItem =
  (
    itemId,
    name,
    unitForMeasure,
    description,
    itemType,
    standardExpDays,
    stdSize,
    brand,
    frozen
  ) =>
  async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      const body = JSON.stringify({
        name,
        unitForMeasure,
        description,
        itemType,
        standardExpDays,
        stdSize,
        brand,
        frozen,
      });
      console.log('Body:', body);

      try {
        let response = await axios.put(
          `${process.env.REACT_APP_API_URL}/fifo/itemId/${itemId}`,
          body,
          config
        );
        console.log(response.data);
        dispatch({
          type: ITEM_UPDATE_SUCCESS,
          payload: response.data,
        });
        // callback({ addedItem: response.data });
      } catch (error) {
        console.log(error);
        dispatch({
          type: ITEM_UPDATE_FAIL,
        });
      }
    } else {
      dispatch({
        type: ITEM_ADDED_FAIL,
      });
    }
  };
export const getItem = (itemId, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    // const body = JSON.stringify({
    //   recipeId,
    //   name,
    //   unitForMeasure,
    //   shelfLife,
    //   brand,
    //   stdSize,
    //   ingredients,
    //   frozen,
    // });

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fifo/itemId/${itemId}`,
        // body,
        config
      );
      console.log(response.data);
      dispatch({
        type: GET_ITEM_SUCCESS,
        payload: response.data,
      });
      callback({ gotItem: response.data });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_ITEM_FAIL,
      });
    }
  } else {
    dispatch({
      type: ITEM_ADDED_FAIL,
    });
  }
};
export const getRecipe = (recipeId, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    // const body = JSON.stringify({
    //   recipeId,
    //   name,
    //   unitForMeasure,
    //   shelfLife,
    //   brand,
    //   stdSize,
    //   ingredients,
    //   frozen,
    // });

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fifo/recipeId/${recipeId}`,
        // body,
        config
      );
      console.log(response.data);
      dispatch({
        type: GET_RECIPE_SUCCESS,
        payload: response.data,
      });
      callback({ gotRecipe: response.data });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_RECIPE_FAIL,
      });
    }
  } else {
    dispatch({
      type: RECIPE_ADDED_FAIL,
    });
  }
};
export const updateState = (newState) => async (dispatch) => {
  dispatch({
    type: UPDATE_STATE,
    payload: newState,
  });
  console.log(newState);
};
export const updateRecipe =
  (
    recipeId,
    name,
    unitForMeasure,
    shelfLife,
    brand,
    stdSize,
    ingredients,
    frozen
  ) =>
  async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      const body = JSON.stringify({
        name,
        unitForMeasure,
        shelfLife,
        brand,
        stdSize,
        ingredients,
        frozen,
      });

      try {
        let response = await axios.put(
          `${process.env.REACT_APP_API_URL}/fifo/recipeId/${recipeId}`,
          body,
          config
        );
        console.log(response.data);
        dispatch({
          type: RECIPE_UPDATE_SUCCESS,
          payload: response.data,
        });
        // callback({ addedRecipe: response.data });
      } catch (error) {
        console.log(error);
        dispatch({
          type: RECIPE_UPDATE_FAIL,
        });
      }
    } else {
      dispatch({
        type: RECIPE_UPDATE_FAIL,
      });
    }
  };

export const addRecipe =
  (
    name,
    unitForMeasure,
    shelfLife,
    brand,
    stdSize,
    ingredients,
    frozen,
    callback
  ) =>
  async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      const body = JSON.stringify({
        name,
        unitForMeasure,
        shelfLife,
        brand,
        stdSize,
        ingredients,
        frozen,
      });

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fifo/getallAndAddRecipe`,
          body,
          config
        );
        console.log(response.data);
        dispatch({
          type: RECIPE_ADDED_SUCCESS,
          payload: response.data,
        });
        callback({ addedRecipe: response.data });
      } catch (error) {
        console.log(error);
        dispatch({
          type: RECIPE_ADDED_FAIL,
        });
      }
    } else {
      dispatch({
        type: RECIPE_ADDED_FAIL,
      });
    }
  };

export const addItemOrRecipeToKitchenForUnEnableRackSystem =
  (kitchen, item, recipe, callback) => async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      const body = JSON.stringify({ kitchen, item, recipe });

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fifo/getallAndAddKitchenForUnEnableRackSystem `,
          body,
          config
        );
        //console.log(response.data)
        dispatch({
          type: ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__SUCCESS,
          payload: response.data,
        });
        callback({
          addedItemOrRecipeToKitchenForUnEnableRackSystem: response.data,
        });
      } catch (error) {
        //console.log(error)
        dispatch({
          type: ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__FAIL,
        });
      }
    } else {
      dispatch({
        type: ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__FAIL,
      });
    }
  };

export const getKitchenData = (kitchenId, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fifo/kitchenData/${kitchenId} `,
        config
      );
      dispatch({
        type: KITCHEN_DATA_LOADED_SUCCESS,
        payload: response.data,
      });
      callback({ kitchenData: response.data });
    } catch (error) {
      dispatch({
        type: KITCHEN_DATA_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: KITCHEN_DATA_LOADED_FAIL,
    });
  }
};

export const getKitchenLiveInventory =
  (kitchenId, callback) => async (dispatch) => {
    console.log(kitchenId);
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fifo/inInventory/${kitchenId} `,
          config
        );
        dispatch({
          type: KITCHEN_LIVE_INVENTORY_LOADED_SUCCESS,
          payload: response.data,
        });
        callback({ kitchenLiveInventory: response.data });
      } catch (error) {
        dispatch({
          type: KITCHEN_LIVE_INVENTORY_LOADED_FAIL,
        });
      }
    } else {
      dispatch({
        type: KITCHEN_LIVE_INVENTORY_LOADED_FAIL,
      });
    }
  };

export const getKitchenWasteInventory =
  (kitchenId, callback) => async (dispatch) => {
    console.log(kitchenId);
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fifo/wasteInventory/${kitchenId} `,
          config
        );
        dispatch({
          type: KITCHEN_WASTE_INVENTORY_LOADED_SUCCESS,
          payload: response.data,
        });
        callback({ kitchenWasteInventory: response.data });
      } catch (error) {
        dispatch({
          type: KITCHEN_WASTE_INVENTORY_LOADED_FAIL,
        });
      }
    } else {
      dispatch({
        type: KITCHEN_WASTE_INVENTORY_LOADED_FAIL,
      });
    }
  };
export const getKitchenTransitInventory =
  (kitchenId, callback) => async (dispatch) => {
    console.log(kitchenId);
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fifo/transitInventory/${kitchenId} `,
          config
        );
        dispatch({
          type: KITCHEN_TRANSIT_INVENTORY_LOADED_SUCCESS,
          payload: response.data,
        });
        callback({ kitchenTransitInventory: response.data });
      } catch (error) {
        dispatch({
          type: KITCHEN_TRANSIT_INVENTORY_LOADED_FAIL,
        });
      }
    } else {
      dispatch({
        type: KITCHEN_TRANSIT_INVENTORY_LOADED_FAIL,
      });
    }
  };
export const getKitchenUsageInventory =
  (kitchenId, callback) => async (dispatch) => {
    console.log(kitchenId);
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fifo/usageInventory/${kitchenId} `,
          config
        );
        dispatch({
          type: KITCHEN_USAGE_INVENTORY_LOADED_SUCCESS,
          payload: response.data,
        });
        callback({ kitchenUsageInventory: response.data });
      } catch (error) {
        dispatch({
          type: KITCHEN_USAGE_INVENTORY_LOADED_FAIL,
        });
      }
    } else {
      dispatch({
        type: KITCHEN_USAGE_INVENTORY_LOADED_FAIL,
      });
    }
  };

export const getKitchenWithId = (kitchenId, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fifo/kitchenId/${kitchenId} `,
        config
      );
      dispatch({
        type: GET_KITCHEN_WITH_ID_SUCCESS,
        payload: response.data,
      });
      callback({ partnerWorkingInKitchen: response.data });
    } catch (error) {
      dispatch({
        type: GET_KITCHEN_WITH_ID_FAIL,
      });
    }
  } else {
    dispatch({
      type: GET_KITCHEN_WITH_ID_FAIL,
    });
  }
};

export const deleteItem = (itemId, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/fifo/itemId/${itemId} `,
        config
      );
      // console.log("delete: ",response.data)
      dispatch({
        type: DELETE_ITEM_SUCCESSFUL,
        payload: response.data,
      });
      callback({ deletedItem: response.data });
    } catch (error) {
      // console.log(error)
      dispatch({
        type: DELETE_ITEM_FAIL,
      });
    }
  } else {
    dispatch({
      type: DELETE_ITEM_FAIL,
    });
  }
};

export const deleteRecipe = (recipeId, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/fifo/recipeId/${recipeId} `,
        config
      );
      //console.log(response.data)
      dispatch({
        type: DELETE_RECIPE_SUCCESSFUL,
        payload: response.data,
      });
      callback({ deletedRecipe: response.data });
    } catch (error) {
      //console.log(error)
      dispatch({
        type: DELETE_RECIPE_FAIL,
      });
    }
  } else {
    dispatch({
      type: DELETE_RECIPE_FAIL,
    });
  }
};

export const addItemsWithExcel =
  (document, brand, callback) => async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      var formData = new FormData();
      formData.append('document', document);
      window.abc = document;
      formData.append('brand', brand);
      formData.append('kitchen', 12);
      // const body = JSON.stringify({ document, brand });

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fifo/addItemWithExcel`,
          formData,
          config
        );
        console.log(response.data);
        dispatch({
          type: ITEMS_ADDED_WITH_EXCEL_SUCCESS,
          payload: response.data,
        });
        callback({ addedItems: response.data });
      } catch (error) {
        // console.log(error.response.data)
        if (error.response.status === 500) {
          console.log('Wrong sheet');
          toast('Your data in excel sheet is wrong.');
        }
        dispatch({
          type: ITEMS_ADDED_WITH_EXCEL_FAIL,
        });
      }
    } else {
      dispatch({
        type: ITEMS_ADDED_WITH_EXCEL_FAIL,
      });
    }
  };

export const addRecipesWithExcel =
  (document, brand, kitchen, callback) => async (dispatch) => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: 'JWT ' + localStorage.getItem('access'),
        },
      };

      var formData = new FormData();
      formData.append('document', document);
      window.abc = document;
      formData.append('brand', brand);
      formData.append('kitchen', kitchen);
      // const body = JSON.stringify({ document, brand });

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fifo/addRecipeWithExcel`,
          formData,
          config
        );
        console.log(response.data);
        dispatch({
          type: RECIPES_ADDED_WITH_EXCEL_SUCCESS,
          payload: response.data,
        });
        callback({ addedRecipes: response.data });
      } catch (error) {
        // console.log(error.response.data)
        if (error.response.status === 500) {
          console.log('Wrong sheet');
          toast('Your data in excel sheet is wrong.');
        }
        dispatch({
          type: RECIPES_ADDED_WITH_EXCEL_FAIL,
        });
      }
    } else {
      dispatch({
        type: RECIPES_ADDED_WITH_EXCEL_FAIL,
      });
    }
  };

export const getUpdatedApp = (callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fifo/getallAndAddAppInfo`,
        config
      );
      console.log(response.data);
      dispatch({
        type: GET_UPDATED_APP_SUCCESS,
        payload: response.data,
      });
      callback({ updatedApp: response.data });
    } catch (error) {
      // console.log(error.response.data)
      if (error.response.status === 500) {
        console.log(error);
      }
      dispatch({
        type: GET_UPDATED_APP_FAIL,
      });
    }
  } else {
    dispatch({
      type: GET_UPDATED_APP_FAIL,
    });
  }
};

export const deleteRecord = (qrCode, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    qrCode = String(qrCode.replaceAll('#', '_'));
    // console.log('fine');
    console.log(qrCode.replaceAll('#', '_'));
    // ⛳ delete record  function giving 200 response for all the qrinputs
    try {
      console.log(
        'url: ',
        `${process.env.REACT_APP_API_URL}/fifo/deleteQREntries/${qrCode}`
      );
      let response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/fifo/deleteQREntries/${qrCode}`,
        config
      );
      //console.log(response.data)
      dispatch({
        type: DELETE_RECORD_SUCCESS,
        // ⛳ showing all entries deleted for all inputs
        payload: response.data,
      });
      callback({ deletedRecord: response.data });
    } catch (error) {
      //console.log(error)
      dispatch({
        type: DELETE_RECORD_FAIL,
      });
    }
  } else {
    dispatch({
      type: DELETE_RECORD_FAIL,
    });
  }
};

export const deleteStaff = (id, callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
    };

    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/fifo/deleteUser/${id}`,
        config
      );
      //console.log(response.data)
      dispatch({
        type: STAFF_DELETION_SUCCESS,
        payload: response.data,
      });
      callback({ deletedStaff: response.data });
    } catch (error) {
      //console.log(error)
      dispatch({
        type: STAFF_DELETION_FAIL,
      });
    }
  } else {
    dispatch({
      type: STAFF_DELETION_FAIL,
    });
  }
};
