import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from '../actions/auth';
import '../styles/footerHack.css'


const Activate = ({ verify }) => {
            const [verified, setVerified] = useState(false);
            const {uid, token} = useParams();
        
            const verify_account = e => {
                // const uid = match.params.uid;
                // const token = match.params.token;

                verify(uid, token);
                setVerified(true);
            };
            
             
            if (verified)
                return <Navigate to='/' />;
    return (
        <div className='container footer-hack' style={{paddingBottom:'15%'}}>
            <div className='d-flex flex-column justify-content-center align-items-center' style={{marginTop: '200px'}}>
            <h1>Verify Your Account:</h1>
            <button
                onClick={verify_account}
                style={{ marginTop: '50px'}}
                type='button'
                className='btn '
            >
                Verify
            </button>

            </div>
        </div>
    );
};


export default connect(null, { verify }) (Activate);
