import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getKitchenUsageInventory, deleteRecord } from '../actions/fifo';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import '../styles/kitchenLiveInventory.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const KitchenUsageInventory = ({
  getKitchenUsageInventory,
  deleteRecord,
  user,
}) => {
  const { state } = useLocation();
  const [kitchen, setKitchen] = useState(null);
  const [kitchenLiveInventoryData, setKitchenLiveInventoryData] = useState({});
  const [inventoryTriger, setInventoryTriger] = useState(0);
  // let inventoryTriger = 0;

  const navigate = useNavigate();

  // Function to group recipe or item data according to expiry date
  const expiryDateGroupHelper = (data) => {
    data = data.reduce((r, a) => {
      r[a.expDate] = r[a.expDate] || [];
      r[a.expDate].push(a);
      return r;
    }, Object.create(null));
    return data;
  };

  const getKitchenUsageInventoryCallback = (event) => {
    console.log(event.kitchenUsageInventory[0]);
    if (event.kitchenUsageInventory[0]) {
      let kitchenLiveInventory = event.kitchenUsageInventory[0];

      // console.log("Result before grouping: ", kitchenLiveInventory)
      // Grouping data according to recipeName or itemName
      kitchenLiveInventory = kitchenLiveInventory.reduce((r, a) => {
        if (a.recipeID) {
          // console.log("in grouping recipeId: ", a.recipeID)
          r[a.recipeID.name] = r[a.recipeID.name] || [];
          r[a.recipeID.name].push(a);
        }
        if (a.itemID) {
          // console.log("in grouping itemId: ", a.itemID.name)
          r[a.itemID.name] = r[a.itemID.name] || [];
          r[a.itemID.name].push(a);
        }

        return r;
      }, Object.create(null));

      let kitchenLiveInventoryFormattedfinalData = {};
      // Grouping every recipe or item data according to expiry date
      for (let key in kitchenLiveInventory) {
        let lol = expiryDateGroupHelper(kitchenLiveInventory[key]);

        const sorted = Object.keys(lol)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = lol[key];

            return accumulator;
          }, {});

        // console.log("sorted:", lol)
        // lol.sort((a,b) => {
        //     return new Date(a) - new Date(b);
        // })
        // console.log("Vinniieeeee: ", sorted)
        kitchenLiveInventoryFormattedfinalData[key] = lol;
      }
      console.log('result: ', kitchenLiveInventoryFormattedfinalData);
      setKitchenLiveInventoryData(kitchenLiveInventoryFormattedfinalData);
    }
  };
  console.log(kitchenLiveInventoryData);
  // const onDeleteInventoryCallback = (e) => {
  //   if (e) {
  //     console.log('asldbnkasnkjdbkja: ', e.deletedRecord);
  //     toast('Inventory Deleted Successfully');
  //     getKitchenUsageInventory(
  //       state.kitchen.id,
  //       getKitchenUsageInventoryCallback
  //     );
  //   }
  // };

  // const onDeleteInventory = (e, qrCode) => {
  //   e.preventDefault();
  //   console.log(qrCode);
  //   if (qrCode) {
  //     if (user) {
  //       if (user.partnerType === 'Admin') {
  //         deleteRecord(qrCode, onDeleteInventoryCallback);
  //       } else {
  //         toast("You don't have right to delete");
  //       }
  //     }
  //   }
  // };

  const reverseString = (str) => {
    return str.split('-').reverse().join('-');
  };

  setInterval(() => {
    setInventoryTriger(inventoryTriger + 1);
    // inventoryTriger += 1
    // console.log("inventoryTriger: ", inventoryTriger);
  }, 600000);

  useEffect(() => {
    if (state.kitchen) {
      // console.log("lol: ", state.kitchen.id)
      setKitchen(state.kitchen);
      getKitchenUsageInventory(
        state.kitchen.id,
        getKitchenUsageInventoryCallback
      );
    }
  }, [inventoryTriger]);

  return (
    <div className='row mx-0 footer-hack'>
      <div className='col-2 px-4 pt-5' style={{ marginLeft: '10px' }}>
        <h6 onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Go Back
        </h6>
      </div>
      <div
        className=' col-12 px-0 kitchen-liveInventory-container  '
        style={{
          ...(Object.keys(kitchenLiveInventoryData).length > 3
            ? { paddingBottom: '10%' }
            : { paddingBottom: '40%' }),
        }}
      >
        <div
          className='row '
          style={{
            textAlign: 'center',
            borderBottom: '0px',
            color: '#CBA769',
            width: '99%',
          }}
        >
          <h2 className=' '>Usage Inventory</h2>
        </div>

        <div className='mt-5 mb-4 table-responsive col-12 border  '>
          <div
            className='accordion accordion-flush '
            id='accordionFlushExample'
          >
            {Object.keys(kitchenLiveInventoryData).map((e, i) => (
              <div className='accordion-item'>
                <h2 className='accordion-header' id={'flush-headingOne' + i}>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={'#flush-collapseOne' + i}
                    aria-expanded='false'
                    aria-controls={'flush-collapseOne' + i}
                  >
                    <h5>{e}</h5>
                  </button>
                </h2>
                <div
                  id={'flush-collapseOne' + i}
                  className='accordion-collapse collapse '
                  aria-labelledby={'flush-headingOne' + i}
                  data-bs-parent={'#accordionFlushExample' + i}
                >
                  <div className='accordion-body'>
                    <div
                      className='accordion accordion-flush'
                      id='accordionFlushExample'
                    >
                      {Object.keys(kitchenLiveInventoryData[e]).map((x, j) => (
                        <div className='accordion-item'>
                          <h2
                            className='accordion-header'
                            id={'flush-headingOne' + j + i}
                          >
                            <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={'#flush-collapseOne' + j + i}
                              aria-expanded='false'
                              aria-controls={'flush-collapseOne' + j + i}
                            >
                              <h5>
                                Batch {j + 1} (Exp: {reverseString(x)})
                              </h5>
                            </button>
                          </h2>
                          <div
                            id={'flush-collapseOne' + j + i}
                            className='accordion-collapse collapse '
                            aria-labelledby={'flush-headingOne' + j + i}
                            data-bs-parent={'#accordionFlushExample' + j + i}
                          >
                            <div className='accordion-body'>
                              <table className='table '>
                                <thead className='thea-dark'>
                                  <tr>
                                    <th scope='col'>No.</th>
                                    <th scope='col'>Recipe</th>
                                    <th scope='col'>Item</th>
                                    <th scope='col'>Quantity</th>
                                    {/* <th scope="col">
                                      {'Scanned By ('}Staff Name)
                                    </th> */}
                                    <th scope='col'>Time</th>
                                    <th scope='col'>Items Used</th>
                                    {/* <th scope='col'>In-stock items</th> */}
                                    <th scope='col'>Date</th>
                                    <th scope='col'>Kitchen</th>
                                    {/* <th scope="col">Action</th> */}
                                  </tr>
                                </thead>
                                {kitchenLiveInventoryData[e][x].map((p, k) => (
                                  <tbody>
                                    <tr key={k}>
                                      <th scope='row'>{k + 1}</th>
                                      <td>
                                        {p.recipeID ? p.recipeID.name : '-'}
                                      </td>
                                      <td>{p.itemID ? p.itemID.name : '-'} </td>
                                      <td>
                                        {p.unitChange}{' '}
                                        {p.recipeID
                                          ? p.recipeID.unitForMeasure
                                          : p.itemID
                                          ? p.itemID.unitForMeasure
                                          : ''}
                                      </td>
                                      {/* <td>
                                        {p.partnerID.first_name}{' '}
                                        {p.partnerID.last_name}
                                      </td> */}
                                      <td> {p.timestamp.substring(11, 19)}</td>
                                      <td>
                                        {p ? p.used + ' ' : ''}{' '}
                                        {p.recipeID
                                          ? p.recipeID.unitForMeasure
                                          : p.itemID
                                          ? p.itemID.unitForMeasure
                                          : ''}{' '}
                                      </td>{' '}
                                      {/* <td>{p ? p.curr_unit : '-'}</td> */}
                                      <td>
                                        {reverseString(
                                          p.timestamp.substring(0, 10)
                                        )}{' '}
                                      </td>
                                      <td>
                                        {p ? p.destKitchen.brand.name : '-'}
                                      </td>
                                      {/* <td>
                                        <button
                                          className="btn "
                                          onClick={(e) =>
                                            onDeleteInventory(e, p.qrcode)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </td> */}
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getKitchenUsageInventory,
  deleteRecord,
})(KitchenUsageInventory);
