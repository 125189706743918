import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  checkBrandExist,
  addLocation,
  kitchenRegistration,
} from "../actions/fifo";
import { useNavigate } from "react-router-dom";
import "../styles/footerHack.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

// import {
//   InfoWindow,
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";

const KitchenRegistration = ({
  checkBrandExist,
  addLocation,
  kitchenRegistration,
}) => {
  const [brand, setBrand] = useState(null);
  const [locationLongitude, setLocationLongitude] = useState(null);
  const [locationLatitude, setLocationLatitude] = useState(null);

  // const [inputFields, setInputFields] = useState([
  //   {keyName:'', keyValue:''}
  // ])

  // const [labelDetails, setLabelDetails] = useState({});

  const defaultKitchenType = "Spoke";

  const navigate = useNavigate();

  // const googleMapState = {
  //   address: "",
  //   city:"",
  //   area:"",
  //   state:"",
  //   zoom:15,
  //   height:400,
  //   mapPosition:{
  //     lat:0,
  //     lng:0,
  //   },
  //   markerPosition:{
  //     lat:0,
  //     lng:0,
  //   }

  // }

  const [formData, setFormData] = useState({
    locationName: "",
    fullAddress: "",

    name: "",
    type: "Spoke",
    enableRackSystem: false,
    enableBoxSystem: false,
  });

  const {
    name,
    type,
    enableRackSystem,
    enableBoxSystem,
    locationName,
    fullAddress,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // const handleChangeInput = (index, event) => {
  //   const values = [...inputFields];
  //   values[index][event.target.name] = event.target.value;
  //   setInputFields(values);
  // }

  // const handleAddFields = () => {
  //   setInputFields([...inputFields, {keyName:'', keyValue:''} ])
  // }

  // const handleRemoveFields = (index) => {
  //   const values = [...inputFields];
  //   values.splice(index, 1);
  //   setInputFields(values);
  // }

  useEffect(() => {
    checkBrandExist()
      .then((brand) => brand.data)
      .then((brandData) => {
        if (brandData.length === 1) {
          //console.log(brandData[0])
          setBrand(brandData[0].id);
        }
      });
  }, []);

  const kitchenRegistrationCallback = (event) => {
    if (event.registeredKitchen) {
      console.log(event.registeredKitchen);
      toast("Kitchen added Successfully!");
      navigate("/dashboard");
    }
  };

  const addLocationCallback = (event) => {
    if (event.addedLocation) {
      console.log(event.addedLocation.id);
      const locationOfKitchen = event.addedLocation.id;
      kitchenRegistration(
        brand,
        locationOfKitchen,
        name,
        enableRackSystem,
        enableBoxSystem,
        type,
        kitchenRegistrationCallback
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // const jsonData = {};
    // inputFields.map(inputField => {
    //   // console.log(inputField.keyName)
    //   // console.log(inputField.keyValue)
    //   jsonData[inputField.keyName] = inputField.keyValue
    // })
    // setLabelDetails(jsonData);
    addLocation(
      locationLongitude,
      locationLatitude,
      locationName,
      fullAddress,
      addLocationCallback
    );
    // console.log(formData, inputFields)
  };

  // const onMarkerDragEnd = (event) => {
  //   let newLat = event.latLang.lat();
  //   let newLng = event.latLang.lng();

  //   console.log('newLat', newLat)
  //   console.log('newLng', newLng)

  // }

  // const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  //   <GoogleMap
  //     defaultZoom={8}
  //     defaultCenter={{ lat: -34.397, lng: 150.644 }}
  //   >
  //     <Marker
  //       draggable={true}
  //       onDragEnd={onMarkerDragEnd}
  //       position={{ lat: -34.397, lng: 150.644 }}
  //     >
  //       <InfoWindow>
  //         <div>
  //           Hello
  //         </div>
  //       </InfoWindow>
  //     </Marker>
  //   </GoogleMap>
  // ));

  return (
    <div className="container mt-5 col-md-6 footer-hack">
      <h1>Kitchen Registration</h1>
      <h4 className="mt-3">Kitchen Details</h4>
      <p> Fill the form to Add your kitchen.</p>
      <form onSubmit={(e) => onSubmit(e)} className="form-inline">
        {/* <label className='form-label mt-3'>Brand: </label>
        <select className="form-select" aria-label="Default select example" value={brand}   name='brand'  onChange={e => onChange(e)}>
            <option  selected >Select Brand</option>  
            <option   value={adminBrandId} >{adminBrandName}</option>  
        </select> */}

        <div className="form-group">
          <label className="form-label mt-3">Kitchen Name: </label>
          <input
            className="form-control"
            type="text"
            placeholder="Kitchen Name*"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
            required
          />
        </div>

        <label className="form-label mt-3">Kitchen Type: </label>
        <select
          className="form-select"
          aria-label="Default select example"
          value={type}
          required
          name="type"
          onChange={(e) => onChange(e)}
        >
          {/* <option defaultValue={defaultKitchenType} value="Spoke">
            Select Kitchen Type*
          </option> */}
          <option value="Central">Central</option>
          <option value="Spoke">Spoke</option>
        </select>

        <h4 className="mt-3">Location Details</h4>
        <div className="form-group">
          <label className="form-label mt-3">Location Name: </label>
          <input
            className="form-control"
            type="text"
            placeholder="Location Name*"
            name="locationName"
            value={locationName}
            onChange={(e) => onChange(e)}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label mt-3">Full Address: </label>
          <textarea
            className="form-control my-3"
            type="text"
            placeholder="Full Address"
            name="fullAddress"
            value={fullAddress}
            onChange={(e) => onChange(e)}
            required
          />
        </div>

        


        <button className="btn  mt-3 my-5" type="submit">
          Register
        </button>
      </form>
    </div>
  );
};

export default connect(null, {
  checkBrandExist,
  addLocation,
  kitchenRegistration,
})(KitchenRegistration);
