import React, { useState, useEffect } from 'react';
import { getAllKitchens } from '../actions/fifo';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { load_user } from '../actions/auth';
import { useNavigate } from 'react-router-dom';
import { getUpdatedApp } from '../actions/fifo';
import '../styles/dashboard.css';
import '../styles/footerHack.css';
import '../App.css';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

toast.configure();
const Dashboard = ({
  getAllKitchens,
  load_user,
  getUpdatedApp,
  isAuthenticated,
  user,
}) => {
  const [allKitchensData, setAllKitchensData] = useState([]);
  const [updatedApp, setUpdatedApp] = useState(null);
  const navigate = useNavigate();

  const kitchenOnClickHandle = (kitchen) => {
    if (user) {
      if (user.partnerType === 'Admin') {
        console.log('In Dashboard', kitchen);
        return navigate('/kitchen-details', {
          state: { kitchen: kitchen, kitchenName: kitchen.name },
        });
      }
      if (user.partnerType === 'Manager') {
        if (user.partnerWorkingForKitchen.id == kitchen.id) {
          console.log('In Dashboard', kitchen);
          return navigate('/kitchen-details', {
            state: { kitchen: kitchen, kitchenName: kitchen.name },
          });
        } else {
          toast('Can access this kitchen');
        }
      }
    }
  };

  const handleGetAllKitchens = (e) => {
    if (e.allKitchensData) {
      // console.log("All kitchens ", e.allKitchensData[0])
      setAllKitchensData(e.allKitchensData);
    }
  };

  const handleLoadUser = (e) => {
    if (e.user) {
      //console.log("User in Dashboard", e.user)
      if (
        e.user.partnerType === 'Manager' &&
        e.user.partnerWorkingForKitchen != null
      ) {
        let kitchenOfManager = e.user.partnerWorkingForKitchen.id;
        allKitchensData.forEach((kitchen) => {
          if (kitchen.id === kitchenOfManager) {
            kitchenOfManager = kitchen;
            setAllKitchensData(kitchenOfManager);
            return;
          }
        });
      }
    }
  };

  // const addCardBorderOpacity = e => {
  //     e.target.style.border = '1px solid white'
  // }

  // const removeCardBorderOpacity = e => {
  //     e.target.style.border = '0.5px solid white'
  // }

  const getUpdatedAppCallback = (event) => {
    if (event.updatedApp) {
      console.log('Updated App: ', event.updatedApp[0].apk);
      // const url =  URL.createObjectURL(new Blob([event.updatedApp[0].apk], {type:".xlsx"}))
      // console.log("Updated url: ", url)
      // setUpdatedApp(url)
      setUpdatedApp(event.updatedApp[0].apk);
    }
  };

  useEffect(() => {
    getAllKitchens(handleGetAllKitchens);
    load_user(handleLoadUser);
    getUpdatedApp(getUpdatedAppCallback);
  }, []);

  return (
    <div
      className='mt-3  footer-hack col-12 px-0 theme-color'
      style={
        allKitchensData.length == 0
          ? { paddingBottom: '70%' }
          : { paddingBottom: '25%' }
      }
    >
      <div className='dashboard-header '>
        <nav className='navbar navbar-expand-lg px-5 '>
          {/* <form className="form-inline my-2 my-lg-0 ">
                                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                            </form> */}
          <ul className='navbar-nav mx-5   text-center'>
            <li className='nav-item'>
              <Link
                className='nav-link '
                to='/kitchen-registration'
                style={{
                  border: '1px solid #CBA769',
                  borderRadius: '5px',
                  color: '#CBA769',
                }}
              >
                {' '}
                Add Kitchen
              </Link>
            </li>
            {/* <li>
                                <a href={updatedApp} style={{border: '1px solid #CBA769', borderRadius: '5px',  color:'#CBA769'}} download> download</a>
                            </li> */}
          </ul>
        </nav>
      </div>
      <div className='dashboard-Content col-12 px-0'>
        <a
          href='https://apkfab.com/scanx/io.ionic.starter/apk?h=011a708d21cf68b1e6f8c52f5e89eee48e72c47e95a0ed08da9e68f11c09df40'
          target='_blank'
          rel='noopener noreferrer'
          className='dashboard-download'
        >
          <button
            type='button'
            className='btn btn-success btn-md  btn-block text-center'
          >
            Download Scanx App
          </button>
        </a>
        <div className='row mx-0 justify-content-center'>
          {allKitchensData.map((kitchen) => (
            <div
              className='mt-3 pt-2 mx-1  kitchen-card '
              key={kitchen.id}
              style={{ display: 'inline-block', cursor: 'pointer ' }}
              onClick={() => kitchenOnClickHandle(kitchen)}
            >
              <div className='card card-border  theme-color'>
                <div className='card-body'>
                  <h5 className='card-title primary-font primary-color'>
                    {' '}
                    {kitchen.name}
                  </h5>
                  <p className='card-text'>
                    <strong>🏠: </strong> {kitchen.location.fullAddress}
                  </p>
                </div>
                <div className='mx-3 mb-3 kitchen-type'>
                  <strong>Type:</strong> <span>{kitchen.type}</span>
                  <span className='mx-3'>
                    <strong>Enabled systems:</strong>{' '}
                    {kitchen.enableBoxSystem ? ' Box,' : ' '}{' '}
                    {kitchen.enableRackSystem ? ' Rack ' : ' '}{' '}
                    {kitchen.enableRackSystem && kitchen.enableBoxSystem
                      ? ' '
                      : 'None '}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getAllKitchens,
  load_user,
  getUpdatedApp,
})(Dashboard);
