import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getKitchenData,
  getKitchenLiveInventory,
  deleteItem,
  deleteRecipe,
  updateState,
  deleteStaff,
} from '../actions/fifo';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../styles/kitchenDetail.css';
import '../App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const KitchenDetails = ({
  getKitchenData,
  getKitchenLiveInventory,
  deleteItem,
  deleteRecipe,
  user,
  deleteStaff,
}) => {
  const { state } = useLocation();
  const [staff, setStaff] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [items, setItems] = useState([]);
  const [kitchen, setKitchen] = useState(null);
  const [kitchenLiveInventory, setKitchenLiveInventory] = useState([]);
  const [kitchenLiveInventoryData, setKitchenLiveInventoryData] = useState({});
  const [kitchenName, setKitchenName] = useState(null);

  const navigate = useNavigate();

  const getKitchenDataCallback = (event) => {
    if (event.kitchenData) {
      const kitchenData = event.kitchenData;
      console.log(kitchenData);
      kitchenData.map((data, index) => {
        if (index === 0) {
          console.log(data);
          setStaff(data);
        }
        if (index === 1) {
          console.log(data);
          setRecipes(data);
        }
        if (index === 2) {
          console.log(data);
          setItems(data);
        }
      });
    }
  };

  // Function to group recipe or item data according to expiry date
  const expiryDateGroupHelper = (data) => {
    data = data.reduce((r, a) => {
      r[a.expDate] = r[a.expDate] || [];
      r[a.expDate].push(a);
      return r;
    }, Object.create(null));
    return data;
  };

  const getKitchenLiveInventoryCallback = (event) => {
    if (event.kitchenLiveInventory) {
      let kitchenLiveInventory = event.kitchenLiveInventory;

      // Grouping data according to recipeName or itemName
      kitchenLiveInventory = kitchenLiveInventory.reduce((r, a) => {
        if (a.recipeID) {
          r[a.recipeID.name] = r[a.recipeID.name] || [];
          r[a.recipeID.name].push(a);
        }
        if (a.itemID) {
          r[a.itemID.name] = r[a.itemID.name] || [];
          r[a.itemID.name].push(a);
        }
        return r;
      }, Object.create(null));

      let kitchenLiveInventoryFormattedfinalData = {};
      // Grouping every recipe or item data according to expiry date
      for (let key in kitchenLiveInventory) {
        let lol = expiryDateGroupHelper(kitchenLiveInventory[key]);
        kitchenLiveInventoryFormattedfinalData[key] = lol;
      }
      setKitchenLiveInventoryData(kitchenLiveInventoryFormattedfinalData);
      console.log('result: ', kitchenLiveInventoryFormattedfinalData);
      setKitchenLiveInventory(event.kitchenLiveInventory);
    }
  };

  const deleteItemCallback = (event) => {
    if (event) {
      toast('Item Deleted Successfully');
      getKitchenData(state.kitchen.id, getKitchenDataCallback);
    }
  };
  const onUpdateRecipe = (id) => {
    console.log(id);

    updateState('recipe');
    return navigate(`/update-recipe/${id}`, {
      state: { kitchen: state.kitchen },
    });
  };
  const onUpdateItem = (id) => {
    console.log(id);

    updateState('recipe');
    return navigate(`/update-item/${id}`, {
      state: { kitchen: state.kitchen },
    });
  };

  const onDeleteItem = (e, itemId) => {
    e.preventDefault();
    if (itemId) {
      if (user) {
        if (user.partnerType === 'Admin') {
          alert(
            'This action cannot be undone. Do you want to delete this item? '
          );

          deleteItem(itemId, deleteItemCallback);
        } else {
          toast("You don't have right to delete");
        }
      }
    }
  };

  const deleteRecipeCallback = (event) => {
    if (event) {
      toast('Recipe Deleted Successfully');
      getKitchenData(state.kitchen.id, getKitchenDataCallback);
    }
  };

  const onDeleteRecipe = (e, recipeID) => {
    e.preventDefault();
    if (recipeID) {
      if (user) {
        if (user.partnerType === 'Admin') {
          alert(
            'This action cannot be undone. Do you want to delete this Recipe? '
          );

          deleteRecipe(recipeID, deleteRecipeCallback);
        } else {
          toast("You don't have right to delete");
        }
      }
    }
  };

  const deleteStaffCallback = (e) => {
    if (e) {
      toast('Staff Deleted Successfully');
      getKitchenData(state.kitchen.id, getKitchenDataCallback);
    }
  };

  const onDeleteStaff = (e, id) => {
    e.preventDefault();
    if (id) {
      if (user) {
        if (user.partnerType === 'Admin') {
          alert(
            'This action cannot be undone. Do you want to delete this staff? '
          );
          deleteStaff(id, deleteStaffCallback);
        } else {
          toast("You don't have right to delete");
        }
      }
    }
  };

  const addStaffOnClickHandler = () => {
    return navigate('/staff-registration', {
      state: { kitchen: state.kitchen },
    });
  };

  const addItemOnClickHandler = () => {
    return navigate('/add-item', { state: { kitchen: state.kitchen } });
  };

  const addRecipeOnClickHandler = () => {
    return navigate('/add-recipe', { state: { kitchen: state.kitchen } });
  };

  const qrLabelOnClickHandler = () => {
    return navigate('/qr-label-maker', {
      state: { kitchen: state.kitchen, items: items, recipes: recipes },
    });
  };

  const liveInventoryOnClickHandler = () => {
    return navigate('/kitchen-live-inventory', {
      state: { kitchen: state.kitchen },
    });
  };

  const transitInventoryOnClickHandler = () => {
    return navigate('/transit-inventory', {
      state: { kitchen: state.kitchen },
    });
  };
  const usageInventoryOnClickHandler = () => {
    return navigate('/usage-inventory', { state: { kitchen: state.kitchen } });
  };

  const wasteInventoryOnClickHandler = () => {
    return navigate('/waste-inventory', { state: { kitchen: state.kitchen } });
  };

  useEffect(() => {
    if (state.kitchen) {
      console.log('lol: ', state.kitchen.id);
      setKitchen(state.kitchen);
      getKitchenData(state.kitchen.id, getKitchenDataCallback);
      getKitchenLiveInventory(
        state.kitchen.id,
        getKitchenLiveInventoryCallback
      );
      setKitchenName(state.kitchenName);
    }
  }, []);

  return (
    <div className='mt-3 col-12 px-0 kitchen-detail-container '>
      <h1 className='row justify-content-center'>{kitchenName}</h1>

      <div className='row mx-0 '>
        <div className='col-12 mt-2 px-0'>
          <div className='row mx-0 flex-nowrap '>
            <div className='col-12 d-flex px-4'>
              <div className='col-1 ' style={{ cursor: 'pointer' }}>
                <h6 onClick={addStaffOnClickHandler}>Add Staff</h6>
              </div>

              <div className='col-1' style={{ cursor: 'pointer' }}>
                <h6 onClick={addItemOnClickHandler}>Add Item</h6>
              </div>

              <div className='col-1' style={{ cursor: 'pointer' }}>
                <h6 onClick={addRecipeOnClickHandler}>Add Recipe</h6>
              </div>

              <div className='col-2 mx-2 ' style={{ cursor: 'pointer' }}>
                <h6 onClick={qrLabelOnClickHandler}>Generate QR Label</h6>
              </div>
              <div className='col-1  ' style={{ cursor: 'pointer' }}>
                <h6 onClick={liveInventoryOnClickHandler}>Live Inventory</h6>
              </div>
              <div
                className='col-2  '
                style={{ cursor: 'pointer', textAlign: 'center' }}
              >
                <h6 onClick={transitInventoryOnClickHandler}>
                  Output Inventory
                </h6>
              </div>
              <div className='col-2  ' style={{ cursor: 'pointer' }}>
                <h6 onClick={usageInventoryOnClickHandler}>Usage Inventory</h6>
              </div>

              {/* <div className="col-2  " style={{cursor:"pointer"}}>
                                    <h6 onClick={usedInventoryOnClickHandler}>Used Inventory</h6>
                                </div> */}
              <div className='col-2  ' style={{ cursor: 'pointer' }}>
                <h6 onClick={wasteInventoryOnClickHandler}>Waste Inventory</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='mt-3 mb-4 table-responsive col-12 border border-primary recipe-table'
        style={{ display: 'inline-block' }}
      >
        <h3>Recipes</h3>
        <table className='table '>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>No.</th>
              <th scope='col'>Name</th>
              <th scope='col'>Unit of Measure</th>
              <th scope='col'>Shelf Life</th>
              <th scope='col'>Ingredients</th>
              <th scope='col'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {recipes.length === 0
              ? 'Add some Recipes using above links'
              : recipes.map((recipes, index) => (
                  <tr key={recipes.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>
                      {recipes.name} {recipes.frozen ? ' (F)' : ''}
                    </td>
                    <td>{recipes.unitForMeasure}</td>
                    <td>{recipes.shelfLife} days</td>
                    <td>
                      {recipes.ingredients.map((item) => item.name + ', ')}
                    </td>
                    <td className='d-flex justify-content-center gap-1'>
                      <button
                        className='btn '
                        onClick={(e) => onUpdateRecipe(recipes.id)}
                      >
                        Edit
                      </button>

                      <button
                        className='btn '
                        onClick={(e) => onDeleteRecipe(e, recipes.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div className='item-staff-table-container row mx-1 '>
        <div className='mt-3  table-responsive col-6 border border-primary item-table'>
          <h3>Items</h3>
          <table className='table '>
            <thead className='thea-dark'>
              <tr>
                <th scope='col'>No.</th>
                <th scope='col'>Name</th>
                <th scope='col'>Unit of Measure</th>
                <th scope='col'>Item Type</th>
                <th scope='col'>Self Life</th>
                <th scope='col'>Description</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.length === 0
                ? 'Add some Items using above links'
                : items.map((item, index) => (
                    <tr key={item.id}>
                      <th scope='row'>{index + 1}</th>
                      <td>
                        {item.name}
                        {item.frozen ? ' (F)' : ''}
                      </td>
                      <td>{item.unitForMeasure}</td>
                      <td>{item.itemType}</td>
                      <td>{item.standardExpDays} days</td>
                      <td>{item.description.slice(0, 30)}</td>
                      <td className='d-flex justify-content-center gap-1'>
                        <button
                          className='btn '
                          onClick={(e) => onUpdateItem(item.id)}
                        >
                          Edit
                        </button>

                        <button
                          className='btn '
                          onClick={(e) => onDeleteItem(e, item.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        <div className='mt-3  table-responsive col-6 border border-primary staff-table'>
          <h3>Staff</h3>
          <table className='table '>
            <thead className='thea-dark'>
              <tr>
                <th scope='col'>No.</th>
                <th scope='col'>User name</th>
                <th scope='col'>Name</th>
                <th scope='col'>Phone</th>
                <th scope='col'>Partner Type</th>
                {/* <th scope="col">Email</th> */}
                {/* <th scope="col">Working in Kitchen</th> */}
                <th scope='col'> Action</th>
              </tr>
            </thead>
            <tbody>
              {staff.length === 0
                ? 'Add some staff using above links'
                : staff.map((staff, index) => (
                    <tr key={staff.id}>
                      <th scope='row'>{index + 1}</th>

                      <td>{staff.username}</td>
                      <td>
                        {staff.first_name} {staff.last_name}{' '}
                      </td>
                      <td>{staff.phoneNumber}</td>
                      <td>{staff.partnerType}</td>
                      {/* <td>{staff.email}</td>
                                <td>{staff.partnerWorkingForKitchen.name}</td> */}
                      <td>
                        <button
                          className='btn '
                          onClick={(e) => onDeleteStaff(e, staff.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getKitchenData,
  getKitchenLiveInventory,
  deleteItem,
  deleteRecipe,
  updateState,
  deleteStaff,
})(KitchenDetails);
