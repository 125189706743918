import React from "react";
import { connect } from "react-redux";

import { Outlet, Route, useNavigate } from "react-router-dom";

const ProtectedRoute = ({isAuthenticated }) => {
    const navigate = useNavigate();

    if(isAuthenticated){
       return <Outlet />
    }
    if(!isAuthenticated){
        navigate('/')
        return null
    }

    // return (
    //     <Route {...rest}  render={(props) => {
    //         if(isAuthenticated){
    //             return <Component {...props}/>;
    //         }
    //         if(!isAuthenticated){
    //             return navigate('/')
    //         }
    //     }} />
    // );
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
})


export default connect(mapStateToProps, {})(ProtectedRoute)