import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  LOGOUT,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAIL,
  ACTIVATION_FAIL,
  ACTIVATION_SUCCESS,
} from './types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const body = JSON.stringify({ token: localStorage.getItem('access') });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/verify/`,
        body,
        config
      );

      if (response.data.code !== 'token_not_valid') {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (error) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_user = (callback) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('access')}`,
        Accept: 'application/json',
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/users/me/`,
        config
      );

      if (response.data.partnerType === 'Staff') {
        dispatch({
          type: LOGIN_FAIL,
        });
        toast("Staff can't login");
      }

      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: response.data,
      });
      callback({ user: response.data });
    } catch (error) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
      // console.log("load user: ",error)
      // return error;
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
    // return "Access Token is not there";
  }
};

export const login = (phoneNumber, password, callback) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ phoneNumber, password });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/jwt/create/`,
      body,
      config
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    dispatch(load_user(callback));
    // callback({"loginSuccess ": response.data});
  } catch (error) {
    if (error === 'Network Error') {
      toast('Network error');
    }

    console.log('LoginError: ', error);
    dispatch({
      type: LOGIN_FAIL,
    });
    if (error.response.status === 401) {
      toast('Login Fail Check your credentials');
    }
  }
};

export const signup =
  (
    username,
    first_name,
    last_name,
    phoneNumber,
    email,
    partnerType,
    partnerBrand,
    partnerWorkingForKitchen,
    password,
    re_password
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      username,
      first_name,
      last_name,
      phoneNumber,
      email,
      partnerType,
      partnerBrand,
      partnerWorkingForKitchen,
      password,
      re_password,
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/`,
        body,
        config
      );

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: SIGNUP_FAIL,
      });
    }
  };
// ⛳not returning
export const addStaffOrManager =
  (
    username,
    first_name,
    last_name,
    phoneNumber,
    email,
    partnerType,
    partnerBrand,
    partnerWorkingForKitchen,
    password,
    re_password
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      username,
      first_name,
      last_name,
      phoneNumber,
      email,
      partnerType,
      partnerBrand,
      partnerWorkingForKitchen,
      password,
      re_password,
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/`,
        body,
        config
      );

      dispatch({
        type: ADD_STAFF_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: ADD_STAFF_FAIL,
      });
    }
  };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ uid, token });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/activation/`,
      body,
      config
    );
    toast('Account Activated successfully. you can login Now!');

    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/reset_password/`,
      body,
      config
    );
    toast('Please check your email for reset password link!');
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
        body,
        config
      );
      toast('Password has been changed successfully!');
      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  toast('Logged out Successfully');
};
