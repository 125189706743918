import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  addItemsWithExcel,
  getItem,
  updateItem,
} from '../actions/fifo';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import '../styles/footerHack.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const UpdateItem = ({
  getItem,
  updateItem,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  addItemsWithExcel,
}) => {
  const { id: itemId } = useParams();
  console.log(typeof recipeId);

  const [itemData, setItemData] = useState({});
  const [brand, setBrand] = useState(null);
  const [kitchen, setKitchen] = useState(null);

  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [itemType, setItemType] = useState('');
  const [unitForMeasure, setUnitForMeasure] = useState('');
  const [standardExpDays, setStandardExpDays] = useState('');
  const [stdSize, setStdSize] = useState(0);

  const [frozen, setFrozen] = useState('False');

  const { state } = useLocation();
  const navigate = useNavigate();

  async function fetchingItem(event) {
    console.log(event);

    setItemData(event.gotItem);

    setItemName(event.gotItem.name);
    setItemType(event.gotItem.itemType);
    setDescription(event.gotItem.description);
    setStandardExpDays(event.gotItem.standardExpDays);
    setStdSize(event.gotItem.stdSize);
    setUnitForMeasure(event.gotItem.unitForMeasure);
    setFrozen(event.gotItem.frozen);

    setBrand(event.gotItem.brand.id);
  }

  useEffect(() => {
    // async function fetchData() {
    //   try {
    // const data = await setRecipeData(fetchrecipeData);

    getItem(itemId, fetchingItem);

    // getRecipe(recipeId).then(res => console.log(res))

    // console.log(data);
    // }
    // catch (e) {
    //   console.error(e);
    // }
    // }
    // fetchData();
    // const fetchData = async ()=>  await ;
    // setRecipeData(fetchrecipeData);

    // console.log(recipeData);
  }, []);

  async function refresh() {
    // setTimeout(getKitchenDataCallback(e), 2000);
    await itemData;
    setItemName(itemData.name);
    setItemType(itemData.itemType);
    setDescription(itemData.description);
    setStandardExpDays(itemData.standardExpDays);
    setStdSize(itemData.stdSize);
    setUnitForMeasure(itemData.unitForMeasure);
    setFrozen(itemData.frozen);

    setBrand(itemData.brand.id);
  }

  const onChangeFrozen = (e) => {
    if (frozen === 'False') {
      setFrozen('True');
    }
    if (frozen === 'True') {
      setFrozen('False');
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log(
      'Data: ',
      itemName,
      unitForMeasure,
      description,
      itemType,
      standardExpDays,
      stdSize,
      brand,
      frozen
    );
    updateItem(
      itemId,
      itemName,
      unitForMeasure,
      description,
      itemType,
      standardExpDays,
      stdSize,
      brand,
      frozen
    );
    toast('item updated Successfully');
    navigate('/kitchen-details', {
      state: { kitchen: state.kitchen },
    });
    // console.log(formData, brand, frozen)
  };

  return (
    <div class="row mx-0 footer-hack">
      <div className="col-2 px-4 pt-5" style={{ marginLeft: '10px' }}>
        <h6 onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Go Back
        </h6>
      </div>
      <div className="col-12">
        <div className="container mt-5 col-md-6">
          <h1>Update Item</h1>
          <button className="btn  mt-3" onClick={() => refresh()}>
            Get Data
          </button>
          <p>
            {' '}
            click on Get Data button if you are unable to see the data in form
          </p>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <label className="form-label mt-3">Item Name: </label>
              <input
                className="form-control"
                type="text"
                placeholder="Item Name*"
                name="itemName"
                value={itemName}
                onChange={(e) => {
                  setItemName(e.target.value.toLocaleLowerCase());
                }}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3">Unit Of Measure : </label>
              <input
                className="form-control"
                type="text"
                placeholder="Unit Of Measure*"
                name="unitForMeasure"
                value={unitForMeasure}
                onChange={(e) => {
                  setUnitForMeasure(e.target.value.toLocaleLowerCase());
                }}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3"> Item Description: </label>
              <textarea
                className="form-control"
                type="text"
                placeholder="Item Description"
                name="description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value.toLocaleLowerCase());
                }}
                required
              />
            </div>

            <label className="form-label mt-3">Item Type: </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={itemType}
              required
              name="itemType"
              onChange={(e) => {
                setItemType(e.target.value);
              }}
            >
              {/* <option defaultValue value="Processed">
              Select Item Type*
            </option> */}
              {console.log('yooi', itemType)}
              <option value="Processed">{itemType}</option>
              <option value="Processed">Processed</option>
              <option value="Perishable">Perishable</option>
              <option value="Manufactured">Manufactured</option>
            </select>
            <small style={{ color: 'wheat' }}>
              If you don't select any default will be Processed.
            </small>

            <div className="form-group">
              <label className="form-label mt-3"> Shelf Life: </label>
              <input
                className="form-control"
                type="number"
                placeholder="Shelf Life*"
                name="standardExpDays"
                min={0}
                value={standardExpDays}
                onChange={(e) => setStandardExpDays(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3"> Standard Packet Size: </label>
              <input
                className="form-control"
                type="number"
                placeholder="Standard Packet Size kilogram and liter*"
                name="stdSize"
                value={stdSize}
                min={0}
                step="any"
                onChange={(e) => setStdSize(e.target.value)}
                required
              />
            </div>

            <div className="form-check form-switch mt-3">
              <label
                className="form-check-label"
                htmlFor="frozen"
                style={{ color: 'white' }}
              >
                Frozen
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="frozen"
                name="frozen"
                value="yes"
                onChange={(e) => onChangeFrozen(e)}
              />
            </div>

            <button className="btn  mt-3" type="submit">
              Update Item
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getItem,
  updateItem,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  addItemsWithExcel,
})(UpdateItem);
