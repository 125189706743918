import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  getRecipe,
  updateRecipe,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  getKitchenData,
} from '../actions/fifo';
import { useNavigate } from 'react-router-dom';
import '../styles/footerHack.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateRecipe = ({
  getRecipe,
  updateRecipe,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  getKitchenData,
}) => {
  const { id: recipeId } = useParams();
  console.log(typeof recipeId);
  const [recipeData, setRecipeData] = useState({});
  // const fetchrecipeData = useSelector((state) => state.fifo.recipeData);

  // console.log(fetchrecipeData);
  // setRecipeData(fetchrecipeData);

  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [brand, setBrand] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientsId, setIngredientsId] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [recipeName, setRecipeName] = useState('');
  const [unitForMeasure, setUnitForMeasure] = useState('');
  const [shelfLife, setShelfLife] = useState(0);
  const [stdSize, setStdSize] = useState(0);
  const [kitchen, setKitchen] = useState(null);

  const [frozen, setFrozen] = useState('False');

  const { state } = useLocation();
  const navigate = useNavigate();
  // console.log(recipeData.brand.id);
  // console.log(recipeData.name);
  // const onChange = (e) => {
  //   if (editstate === 'recipe') {
  //     let fetchRecipeData = {
  //       recipeName: recipeData.name,
  //       unitForMeasure: recipeData.unitForMeasure,
  //       shelfLife: recipeData.shelfLife,
  //       stdSize: recipeData.stdSize,
  //     };
  //     console.log(fetchRecipeData);
  //     console.log('yoo');
  //     console.log(formData);
  //     setFormData(fetchRecipeData);
  //   } else {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };
  async function fetchingRecipe(event) {
    console.log(event);

    setRecipeData(event.gotRecipe);

    setRecipeName(event.gotRecipe.name);
    setStdSize(event.gotRecipe.stdSize);
    setShelfLife(event.gotRecipe.shelfLife);
    setUnitForMeasure(event.gotRecipe.unitForMeasure);
    setFrozen(event.gotRecipe.frozen);
    setIngredients(event.gotRecipe.ingredients);
    let id = event.gotRecipe.ingredients.map((ingredient) => ingredient.id);
    console.log(id);
    setIngredientsId(id);
    setBrand(event.gotRecipe.brand.id);
  }
  console.log(ingredients);
  useEffect(() => {
    // async function fetchData() {
    //   try {
    // const data = await setRecipeData(fetchrecipeData);

    getRecipe(recipeId, fetchingRecipe);

    // getRecipe(recipeId).then(res => console.log(res))

    // console.log(data);
    // }
    // catch (e) {
    //   console.error(e);
    // }
    // }
    // fetchData();
    // const fetchData = async ()=>  await ;
    // setRecipeData(fetchrecipeData);

    // console.log(recipeData);
  }, []);
  // console.log(formdata);

  // const getKitchenDataCallback = (event) => {
  //   if (event.kitchenData) {
  //     const kitchenData = event.kitchenData;
  //     console.log(kitchenData);
  //     console.log('Till here');
  //     kitchenData.map((data, index) => {
  //       console.log(recipeId);
  //       if (index === 2) {
  //         console.log(data);
  //         console.log(recipeId);
  //         setItems(data);

  //         let kitchenrecipeData = kitchenData[1].find(
  //           (item) => item.id === parseInt(recipeId)
  //         );

  //         setRecipeData(kitchenrecipeData);
  //         console.log(recipeData);
  //         setRecipeData(kitchenrecipeData);
  //         setRecipeName(recipeData.name);
  //         setStdSize(recipeData.stdSize);
  //         setShelfLife(recipeData.shelfLife);
  //         setUnitForMeasure(recipeData.unitForMeasure);
  //         setFrozen(recipeData.frozen);
  //         setIngredients(recipeData.ingredients);
  //         console.log(recipeData.name);
  //         console.log(recipeData);
  //         console.log(kitchenData[1]);
  //         console.log('running');
  //         // console.log(recipeData.find((item) => item.id === 3));
  //         console.log(recipeData);
  //       }
  //     });
  //   }
  // };
  // console.log(recipeData);
  async function refresh() {
    // setTimeout(getKitchenDataCallback(e), 2000);
    await recipeData;
    setTimeout(console.log(recipeData), 2000);
    setRecipeName(recipeData.name);
    setStdSize(recipeData.stdSize);
    setShelfLife(recipeData.shelfLife);
    setUnitForMeasure(recipeData.unitForMeasure);
    setFrozen(recipeData.frozen);
    setIngredients(recipeData.ingredients);
    setBrand(recipeData.brand.id);
  }

  const handleSelect = (selectedItems) => {
    const userSelected = [];
    for (let i = 0; i < selectedItems.length; i++) {
      userSelected.push(parseInt(selectedItems[i].value));
    }
    setSelectedIngredients(userSelected);
    console.log(selectedIngredients);
    console.log('items: ', userSelected);
  };
  // const Refresh = () => {
  //   getKitchenDataCallback();
  // };

  // const getRecipeDataCallback = (event) => {
  //   console.log('not at all running');

  //   if (event.recipeData) {
  //     console.log('running');

  //     const recipeData = event.recipeData;
  //     setRecipeData(recipeData);
  //     console.log(recipeData);
  //     //  setTimeout(console.log(recipeData), 2000);
  //     setRecipeName(recipeData.name);
  //     setStdSize(recipeData.stdSize);
  //     setShelfLife(recipeData.shelfLife);
  //     setUnitForMeasure(recipeData.unitForMeasure);
  //     setFrozen(recipeData.frozen);
  //     setIngredients(recipeData.ingredients);
  //   } else {
  //     console.log('not running');
  //   }
  // };

  // const addItemOrRecipeToKitchenForUnEnableRackSystemCallback = (e) => {
  //   if (e.addedItemOrRecipeToKitchenForUnEnableRackSystem) {
  //     console.log(e.addedItemOrRecipeToKitchenForUnEnableRackSystem);
  //     toast('Recipe Updated Successfully');
  //     return navigate('/kitchen-details', {
  //       state: { kitchen: state.kitchen },
  //     });
  //   }
  // };

  // const addRecipeCallback = (e) => {
  //   if (e.addedRecipe) {
  //     addItemOrRecipeToKitchenForUnEnableRackSystem(
  //       state.kitchen.id,
  //       item,
  //       e.addedRecipe.id,
  //       addItemOrRecipeToKitchenForUnEnableRackSystemCallback
  //     );
  //   }
  // };

  const onChangeFrozen = (e) => {
    if (frozen === 'False') {
      setFrozen('True');
    }
    if (frozen === 'True') {
      setFrozen('False');
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(brand, ingredients);
    updateRecipe(
      recipeId,
      recipeName,
      unitForMeasure,
      shelfLife,
      brand,
      stdSize,
      ingredientsId,
      frozen
    );
    toast('recipe updated Successfully');
    navigate('/kitchen-details', {
      state: { kitchen: state.kitchen },
    });
  };

  return (
    <div class="row mx-0 footer-hack">
      <div className="col-2 px-4 pt-5" style={{ marginLeft: '10px' }}>
        <h6 onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Go Back
        </h6>
      </div>
      <div className="col-12">
        {/* <div className="container mt-5 col-md-6 ">

          <form onSubmit={(e) => handleSubmitExcel(e)}>
            <div className="form-group">
              <label className="form-label mt-3">Upload File: </label>
              <input
                className="form-control"
                type="file"
                placeholder="Upload a excel sheet with item data*"
                accept=".xlsx"
                name="document"
                onChange={(e) => setDocument(e.target.files[0])}
                required
              />
            </div>
            <button className="btn  mt-3">Upload</button>
          </form>
        </div> */}

        <div className="container mt-5 col-md-6 ">
          <h1>Update Recipe</h1>
          <button className="btn  mt-3" onClick={() => refresh()}>
            Get Data
          </button>
          <p>
            {' '}
            click on Get Data button if you are unable to see the data in form
          </p>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <label className="form-label mt-3">Recipe Name: </label>
              <input
                className="form-control"
                type="text"
                placeholder="Recipe Name*"
                name="recipeName"
                value={recipeName}
                onChange={(e) => {
                  setRecipeName(e.target.value.toLocaleLowerCase());
                }}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3">Unit Of Measure : </label>
              <input
                className="form-control"
                type="text"
                placeholder="Unit Of Measure*"
                name="unitForMeasure"
                value={unitForMeasure}
                onChange={(e) => {
                  setUnitForMeasure(e.target.value.toLocaleLowerCase());
                }}
                required
              />
            </div>

            <div className="form-group ">
              <label className="form-label mt-3"> Shelf Life: </label>
              <input
                className="form-control"
                type="number"
                placeholder="Shelf Life in Days*"
                name="shelfLife"
                value={shelfLife}
                min={0}
                onChange={(e) => setShelfLife(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3"> Standard Packet Size: </label>
              <input
                className="form-control"
                type="number"
                placeholder="Standard Packet Size kilogram and liter*"
                name="stdSize"
                value={stdSize}
                min={0}
                step="any"
                onChange={(e) => setStdSize(e.target.value)}
                required
              />
            </div>

            <div className="form-group ">
              <label className="form-label mt-3"> Select ingredients: </label>
              <select
                className="form-select mt-0.5"
                multiple={true}
                aria-label="multiple select ingredients"
                value={selectedIngredients}
                // onChange={(e) => {
                //   handleSelect(e.target.selectedOptions);
                // }}
                name="ingredients"
              >
                {ingredients.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>

            <div className="form-check form-switch mt-3">
              <label
                className="form-check-label"
                htmlFor="frozen"
                style={{ color: 'white' }}
              >
                Frozen
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="frozen"
                name="frozen"
                value="yes"
                onChange={(e) => onChangeFrozen(e)}
              />
            </div>
            <button className="btn  mt-3" type="submit">
              Update Recipe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getRecipe,
  updateRecipe,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  getKitchenData,
})(UpdateRecipe);
