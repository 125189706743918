import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  addItem,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  addItemsWithExcel,
} from '../actions/fifo';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import '../styles/footerHack.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const AddItem = ({
  addItem,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  addItemsWithExcel,
}) => {
  const [recipe, setRecipe] = useState(null);
  const [brand, setBrand] = useState(null);
  const [kitchen, setKitchen] = useState(null);
  const [formData, setFormData] = useState({
    itemName: '',
    unitForMeasure: '',
    description: '',
    itemType: 'Processed',
    standardExpDays: '',
    stdSize: 0,
  });
  const [frozen, setFrozen] = useState('False');

  const { register, handleSubmit } = useForm();

  const [document, setDocument] = useState(null);

  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    itemName,
    unitForMeasure,
    description,
    itemType,
    standardExpDays,
    stdSize,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addItemOrRecipeToKitchenForUnEnableRackSystemForExcelCallback = (e) => {
    if (e.addedItemOrRecipeToKitchenForUnEnableRackSystem) {
      console.log(e.addedItemOrRecipeToKitchenForUnEnableRackSystem);
    }
  };

  const addItemsWithExcelCallback = (event) => {
    if (event.addedItems) {
      console.log('lol addedItems: ', event.addedItems);
      console.log('Len:', event.addedItems.length);
      let count = 0;
      console.log('working');
      for (let i = 0; i < event.addedItems.length; i++) {
        addItemOrRecipeToKitchenForUnEnableRackSystem(
          state.kitchen.id,
          event.addedItems[i].id,
          recipe,
          addItemOrRecipeToKitchenForUnEnableRackSystemForExcelCallback
        );
        count++;
      }
      console.log('count: ', count);
      if (count === event.addedItems.length) {
        toast('Items Added Successfully');
        return navigate('/kitchen-details', {
          state: { kitchen: state.kitchen },
        });
      }
    }
  };

  const handleSubmitExcel = (e) => {
    e.preventDefault();

    addItemsWithExcel(document, brand, addItemsWithExcelCallback);
  };

  useEffect(() => {
    if (state) {
      setBrand(state.kitchen.brand.id);
      setKitchen(state.kitchen.id);
    }
    // console.log(state.kitchen.brand.id)
  }, []);

  const addItemOrRecipeToKitchenForUnEnableRackSystemCallback = (e) => {
    if (e.addedItemOrRecipeToKitchenForUnEnableRackSystem) {
      console.log(e.addedItemOrRecipeToKitchenForUnEnableRackSystem);
      toast('Item Added Successfully');
      return navigate('/kitchen-details', {
        state: { kitchen: state.kitchen },
      });
    }
  };

  const addItemCallback = (e) => {
    if (e.addedItem) {
      addItemOrRecipeToKitchenForUnEnableRackSystem(
        state.kitchen.id,
        e.addedItem.id,
        recipe,
        addItemOrRecipeToKitchenForUnEnableRackSystemCallback
      );
    }
  };

  const onChangeFrozen = (e) => {
    if (frozen === 'False') {
      setFrozen('True');
    }
    if (frozen === 'True') {
      setFrozen('False');
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log(
      'Data: ',
      itemName,
      unitForMeasure,
      description,
      itemType,
      standardExpDays,
      stdSize,
      brand,
      frozen
    );
    addItem(
      itemName,
      unitForMeasure,
      description,
      itemType,
      standardExpDays,
      stdSize,
      brand,
      frozen,
      addItemCallback
    );
    // console.log(formData, brand, frozen)
  };

  return (
    <div class="row mx-0 footer-hack">
      <div className="col-2 px-4 pt-5" style={{ marginLeft: '10px' }}>
        <h6 onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Go Back
        </h6>
      </div>
      <div className="col-12">
        <div className="container mt-5 col-md-6 ">
          <h1>Add Items Using Excel Sheet</h1>
          <p> Upload a excel sheet with item data to add your Items.</p>
          <a
            href={
              process.env.PUBLIC_URL + 'sample excel sheets/ItemDataFormat.xlsx'
            }
            download="ItemDataFormat.xlsx"
            style={{
              color: '#CBA769',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
          >
            Download Sample Excel Sheet
          </a>
          <form onSubmit={(e) => handleSubmitExcel(e)}>
            <div className="form-group">
              <label className="form-label mt-3">Upload File: </label>
              <input
                className="form-control"
                type="file"
                placeholder="Upload a excel sheet with item data*"
                accept=".xlsx"
                name="document"
                onChange={(e) => setDocument(e.target.files[0])}
                required
              />
            </div>
            <button className="btn  mt-3">Upload</button>
          </form>
        </div>

        <div className="container mt-5 col-md-6">
          <h1>Add Item</h1>
          <p> Fill the form to Add your Item.</p>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <label className="form-label mt-3">Item Name: </label>
              <input
                className="form-control"
                type="text"
                placeholder="Item Name*"
                name="itemName"
                value={itemName}
                onChange={(e) => {
                  e.target.value = e.target.value.toLocaleLowerCase();
                  onChange(e);
                }}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3">Unit Of Measure : </label>
              <input
                className="form-control"
                type="text"
                placeholder="Unit Of Measure*"
                name="unitForMeasure"
                value={unitForMeasure}
                onChange={(e) => {
                  e.target.value = e.target.value.toLocaleLowerCase();
                  onChange(e);
                }}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3"> Item Description: </label>
              <textarea
                className="form-control"
                type="text"
                placeholder="Item Description"
                name="description"
                value={description}
                onChange={(e) => {
                  e.target.value = e.target.value.toLocaleLowerCase();
                  onChange(e);
                }}
                required
              />
            </div>

            <label className="form-label mt-3">Item Type: </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={itemType}
              required
              name="itemType"
              onChange={(e) => onChange(e)}
            >
              {/* <option defaultValue value="Processed">
              Select Item Type*
            </option> */}
              <option value="Processed" selected>
                Processed
              </option>
              <option value="Perishable">Perishable</option>
              <option value="Manufactured">Manufactured</option>
            </select>
            <small style={{ color: 'wheat' }}>
              If you don't select any default will be Processed.
            </small>

            <div className="form-group">
              <label className="form-label mt-3"> Shelf Life: </label>
              <input
                className="form-control"
                type="number"
                placeholder="Shelf Life*"
                name="standardExpDays"
                min={0}
                value={standardExpDays}
                onChange={(e) => onChange(e)}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-3"> Standard Packet Size: </label>
              <input
                className="form-control"
                type="number"
                placeholder="Standard Packet Size kilogram and liter*"
                name="stdSize"
                value={stdSize}
                min={0}
                step="any"
                onChange={(e) => onChange(e)}
                required
              />
            </div>

            <div className="form-check form-switch mt-3">
              <label
                className="form-check-label"
                htmlFor="frozen"
                style={{ color: 'white' }}
              >
                Frozen
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="frozen"
                name="frozen"
                value="yes"
                onChange={(e) => onChangeFrozen(e)}
              />
            </div>

            <button className="btn  mt-3" type="submit">
              Add Item
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  addItem,
  addItemOrRecipeToKitchenForUnEnableRackSystem,
  addItemsWithExcel,
})(AddItem);
