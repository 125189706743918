import {
  UPDATE_STATE,
  CHECK_BRAND_EXIST_SUCCESS,
  CHECK_BRAND_EXIST_FAIL,
  BRAND_REGISTRATION_SUCCESS,
  BRAND_REGISTRATION_FAIL,
  GET_ALL_KITCHENS_SUCCESS,
  GET_ALL_KITCHENS_FAIL,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAIL,
  KITCHEN_REGISTRATION_SUCCESS,
  KITCHEN_REGISTRATION_FAIL,
  ITEM_ADDED_SUCCESS,
  ITEM_ADDED_FAIL,
  KITCHEN_DATA_LOADED_SUCCESS,
  KITCHEN_DATA_LOADED_FAIL,
  ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__SUCCESS,
  ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__FAIL,
  RECIPE_ADDED_SUCCESS,
  RECIPE_ADDED_FAIL,
  KITCHEN_LIVE_INVENTORY_LOADED_SUCCESS,
  KITCHEN_LIVE_INVENTORY_LOADED_FAIL,
  KITCHEN_TRANSIT_INVENTORY_LOADED_SUCCESS,
  KITCHEN_TRANSIT_INVENTORY_LOADED_FAIL,
  KITCHEN_WASTE_INVENTORY_LOADED_SUCCESS,
  KITCHEN_WASTE_INVENTORY_LOADED_FAIL,
  GET_KITCHEN_WITH_ID_SUCCESS,
  GET_KITCHEN_WITH_ID_FAIL,
  DELETE_ITEM_SUCCESSFUL,
  DELETE_ITEM_FAIL,
  DELETE_RECIPE_SUCCESSFUL,
  DELETE_RECIPE_FAIL,
  ITEMS_ADDED_WITH_EXCEL_SUCCESS,
  ITEMS_ADDED_WITH_EXCEL_FAIL,
  RECIPES_ADDED_WITH_EXCEL_SUCCESS,
  RECIPES_ADDED_WITH_EXCEL_FAIL,
  GET_UPDATED_APP_SUCCESS,
  GET_UPDATED_APP_FAIL,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_FAIL,
  STAFF_DELETION_SUCCESS,
  STAFF_DELETION_FAIL,
  GET_RECIPE_SUCCESS,
  GET_RECIPE_FAIL,
  RECIPE_UPDATE_SUCCESS,
  RECIPE_UPDATE_FAIL,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAIL,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_FAIL,
  KITCHEN_USAGE_INVENTORY_LOADED_SUCCESS,
  KITCHEN_USAGE_INVENTORY_LOADED_FAIL,
} from '../actions/types';

const initialState = {
  allkitchensData: null,
  kitchenData: null,
  kitchenLiveInventory: null,
  kitchenWasteInventory: null,

  kitchenTransitInventory: null,
  partnerWorkingInKitchen: null,
  brandExist: null,
  updatedApp: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_KITCHENS_SUCCESS:
      return {
        ...state,
        allkitchensData: payload,
      };
    case UPDATE_STATE:
      return {
        ...state,
        updateState: payload,
      };

    case GET_ALL_KITCHENS_FAIL:
      return {
        ...state,
        allkitchensData: null,
      };
    case KITCHEN_DATA_LOADED_SUCCESS:
      return {
        ...state,
        kitchenData: payload,
      };
    case KITCHEN_DATA_LOADED_FAIL:
      return {
        ...state,
        kitchenData: null,
      };
    case KITCHEN_LIVE_INVENTORY_LOADED_SUCCESS:
      return {
        ...state,
        kitchenLiveInventory: payload,
      };
    case KITCHEN_USAGE_INVENTORY_LOADED_SUCCESS:
      return {
        ...state,
        kitchenUsageInventory: payload,
      };
    case KITCHEN_USAGE_INVENTORY_LOADED_FAIL:
      return {
        ...state,
        kitchenUsageInventory: null,
      };

    case KITCHEN_WASTE_INVENTORY_LOADED_SUCCESS:
      return {
        ...state,
        kitchenWasteInventory: payload,
      };
    case KITCHEN_TRANSIT_INVENTORY_LOADED_SUCCESS:
      return {
        ...state,
        kitchenTransitInventory: payload,
      };
    case KITCHEN_LIVE_INVENTORY_LOADED_FAIL:
      return {
        ...state,
        kitchenLiveInventory: null,
      };
    case KITCHEN_WASTE_INVENTORY_LOADED_FAIL:
      return {
        ...state,
        kitchenWasteInventory: null,
      };
    case KITCHEN_TRANSIT_INVENTORY_LOADED_FAIL:
      return {
        ...state,
        kitchenTransitInventory: null,
      };
    case GET_KITCHEN_WITH_ID_SUCCESS:
      return {
        ...state,
        partnerWorkingInKitchen: payload,
      };

    case GET_KITCHEN_WITH_ID_FAIL:
      return {
        ...state,
        partnerWorkingInKitchen: null,
      };
    case CHECK_BRAND_EXIST_SUCCESS:
      return {
        ...state,
        brandExist: payload,
      };
    case CHECK_BRAND_EXIST_FAIL:
      return {
        ...state,
        brandExist: null,
      };

    case GET_UPDATED_APP_SUCCESS:
      return {
        ...state,
        updatedApp: payload,
      };
    case GET_RECIPE_SUCCESS:
      return {
        ...state,
        recipeData: payload,
      };
    case GET_RECIPE_FAIL:
      return {
        ...state,
        recipeData: null,
      };

    case GET_ITEM_SUCCESS:
      return {
        ...state,
        itemData: payload,
      };
    case GET_ITEM_FAIL:
      return {
        ...state,
        itemData: null,
      };

    case GET_UPDATED_APP_FAIL:
    case BRAND_REGISTRATION_SUCCESS:
    case BRAND_REGISTRATION_FAIL:
    case ADD_LOCATION_SUCCESS:
    case ADD_LOCATION_FAIL:
    case KITCHEN_REGISTRATION_SUCCESS:
    case KITCHEN_REGISTRATION_FAIL:
    case ITEM_ADDED_SUCCESS:
    case ITEM_ADDED_FAIL:
    case ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__SUCCESS:
    case ITEM_OR_RECIPE_TO_KITCHENFORUNENABLERACKSYSTEM_ADDED__FAIL:
    case RECIPE_UPDATE_SUCCESS:
    case RECIPE_UPDATE_FAIL:
    case ITEM_UPDATE_SUCCESS:
    case ITEM_UPDATE_FAIL:

    case RECIPE_ADDED_SUCCESS:
    case RECIPE_ADDED_FAIL:
    case DELETE_ITEM_SUCCESSFUL:
    case DELETE_ITEM_FAIL:
    case DELETE_RECIPE_SUCCESSFUL:
    case DELETE_RECIPE_FAIL:
    case ITEMS_ADDED_WITH_EXCEL_SUCCESS:
    case ITEMS_ADDED_WITH_EXCEL_FAIL:
    case RECIPES_ADDED_WITH_EXCEL_SUCCESS:
    case RECIPES_ADDED_WITH_EXCEL_FAIL:
    case DELETE_RECORD_SUCCESS:
    case DELETE_RECORD_FAIL:
    case STAFF_DELETION_SUCCESS:
    case STAFF_DELETION_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
