import React, { useState } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { signup } from '../actions/auth';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import '../styles/signup.css';
import '../styles/footerHack.css';

toast.configure();

const Signup = ({ signup, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    phoneNumber: '',
    email: '',
    partnerType: 'Admin',
    partnerBrand: '',
    partnerWorkingForKitchen: '',
    password: '',
    re_password: '',
  });

  const navigate = useNavigate();

  const [accountCreated, setAccountCreated] = useState(false);

  const {
    username,
    first_name,
    last_name,
    phoneNumber,
    email,
    partnerType,
    partnerBrand,
    partnerWorkingForKitchen,
    password,
    re_password,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    if (password === re_password) {
      var createdAdmin = signup(
        username,
        first_name,
        last_name,
        phoneNumber,
        email,
        partnerType,
        partnerBrand,
        partnerWorkingForKitchen,
        password,
        re_password
      );

      createdAdmin
        .then((data) => {
          if (data) {
            console.log('created user:', data);
            setAccountCreated(true);
            toast('Account created Successfully!');
            toast('Please check your email and Activate your account!');
          } else {
            toast('Account creation failed!');
            toast(
              'Please check your user name, email or phone Number, one of these already exist!'
            );
          }
        })
        .catch((err) => console.log('err: ', err));
    }
  };

  const myFunction = () => {
    var x = document.getElementsByClassName('myInput');
    if (x[0].type === 'password' || x[1].type === 'password') {
      x[0].type = 'text';
      x[1].type = 'text';
    } else {
      x[0].type = 'password';
      x[1].type = 'password';
    }
  };

  // if(isAuthenticated) {
  //     return <Navigate to='' />
  // }
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  if (accountCreated) {
    return <Navigate key={accountCreated} to="/login" />;
  }

  return (
    <div className="container mt-5 col-md-6 footer-hack">
      <h1>Register for an Account</h1>
      <p>Create an account to use our services.</p>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <label className="form-label" for="username">
            Username:{' '}
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Username* "
            name="username"
            onChange={(e) => onChange(e)}
            value={username}
            required
          />
          <small style={{ color: 'wheat' }}>
            User-name may contain alphanumeric, _ , @ , + , . and - characters
          </small>
        </div>

        <div className="form-group">
          <label className="form-label mt-3">First Name: </label>
          <input
            className="form-control"
            type="text"
            placeholder="First Name*"
            name="first_name"
            onChange={(e) => onChange(e)}
            value={first_name}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label mt-3">Last Name: </label>
          <input
            className="form-control"
            type="text"
            placeholder="Last Name*"
            name="last_name"
            onChange={(e) => onChange(e)}
            value={last_name}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label mt-3">Phone Number: </label>
          <input
            className="form-control"
            type="tel"
            placeholder="Phone Number*"
            name="phoneNumber"
            onChange={(e) => onChange(e)}
            value={phoneNumber}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label mt-3">Email Address: </label>
          <input
            className="form-control"
            type="email"
            placeholder="Email*"
            name="email"
            onChange={(e) => onChange(e)}
            value={email}
            required
          />
        </div>

        <label className="form-label mt-3">Partner Type: </label>
        <select
          className="form-select"
          required
          aria-label="Default select example"
          value={partnerType}
          name="partnerType"
          onChange={(e) => onChange(e)}
        >
          {/* <option selected >Select your user Type*</option> */}
          <option value="Admin">Admin</option>
          {isAuthenticated === null && <option value="Manager">Manager</option>}
          {isAuthenticated === null && <option value="Staff">Staff</option>}
        </select>

        <div className="form-group">
          <label className="form-label mt-3">Password: </label>
          <input
            className="form-control myInput"
            type="password"
            placeholder="Password*"
            name="password"
            onChange={(e) => onChange(e)}
            value={password}
            minLength="8"
            required
          />
          <div className="validation-container">
            <ul className="validation-list">
              <li>Must be atleast 8 characters</li>
              <li>Must contain atleast 1 number</li>
              <li>Must contain atleast 1 special character</li>
            </ul>
          </div>
        </div>
        <div className="form-group">
          <label className="form-label mt-3">Confirm Password: </label>
          <input
            className="form-control myInput"
            type="password"
            placeholder="Confirm Password*"
            name="re_password"
            onChange={(e) => onChange(e)}
            value={re_password}
            minLength="8"
            required
          />
          <small>
            <input
              type="checkbox"
              onClick={myFunction}
              style={{ marginTop: '10px', marginRight: '10px' }}
            />
            Show Password
          </small>
        </div>
        <button className="btn  mt-3" type="submit">
          Register
        </button>
      </form>
      <p className="mt-3">
        Already have an Account? <br />{' '}
        <Link to="/login" className="text-decoration-none">
          Sign In
        </Link>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(Signup);
